import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, forwardRef } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { CategoryPipe } from '../pipes/category.pipe';
import { FilterPipe } from '../pipes/filter.pipe';
import { ChatPipe } from '../pipes/chat.pipe';
import { SortPipe } from '../pipes/sort.pipe';
import { LocationTidyPipe } from '../pipes/locationtidy.pipe';
import { ItemIdPipe } from '../pipes/itemid.pipe';
import { FormsModule, ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { WindowService } from '../services/window.service';
import { PaymentSingleService } from '../services/payment_single.service';
import { ItemService } from '../services/item.service';
import { MessagingService } from '../services/messaging.service';
import { PaginationService } from '../services/pagination.service';
import { ModalService } from '../services/modal.service';
import { OrderModule } from 'ngx-order-pipe';
import { AngularFireModule} from '@angular/fire';
import { AngularFireAuthModule} from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctions } from '@angular/fire/functions';
// import { AngularFirePerformanceModule } from '@angular/fire/performance';

import { AppComponent } from './app.component';
import { InfoComponent } from './info/info.component';
import { ContactComponent } from './contact/contact.component';
import { AdComponent } from './ad/ad.component';
import { AdpageComponent } from './adpage/adpage.component';
import { LoginComponent } from './login/login.component';
import { UserFormComponent } from './user-form/user-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DropZoneDirective } from './drop-zone.directive';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileSizePipe } from '../pipes/file-size.pipe';
import { UnreadPipe } from '../pipes/unread.pipe';

import { ActionComponent } from './action/action.component';
// import { ItemsComponent } from './items/items.component';
import { AddItemComponent } from './add-item/add-item.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { BiddingFormComponent } from './bidding-form/bidding-form.component';
import { BidsTableComponent } from './bids-table/bids-table.component';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatFormFieldModule, MatSelectModule, MatInputModule, MatTooltipModule, MatSliderModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { StarRatingModule } from 'angular-star-rating';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';

import { UserActivityComponent } from './user-activity/user-activity.component';
import { MessengerComponent } from './messenger/messenger.component';
import { CatGalleryComponent } from './cat-gallery/cat-gallery.component';
import { SubcatGalleryComponent } from './subcat-gallery/subcat-gallery.component';
import { MyItemComponent } from './my-item/my-item.component';
import { MyBidTableComponent } from './my-bid-table/my-bid-table.component';
import { UserActivitySummaryComponent } from './user-activity-summary/user-activity-summary.component';
import { UserRatingsComponent } from './user-ratings/user-ratings.component';
import { AlertsComponent } from './alerts/alerts.component';
import { VerifyPhoneComponent } from './verify-phone/verify-phone.component';
import { UpgradeUserComponent } from './upgrade-user/upgrade-user.component';
import { ScrollableDirective } from './scrollable.directive';
import { MessengerChatComponent } from './messenger-chat/messenger-chat.component';
import { ImgUploadResizeComponent } from './img-upload-resize/img-upload-resize.component';
import { UserSummaryComponent } from './user-summary/user-summary.component';
import { ProfilePublicComponent } from './profile-public/profile-public.component';
import 'hammerjs';

import {
  LyThemeModule,
  LY_THEME
} from '@alyle/ui';

import { LyResizingCroppingImageModule } from '@alyle/ui/resizing-cropping-images';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { MyHammerConfig } from './hammer-config';

export const firebaseConfig = environment.firebaseConfig;

@NgModule({
  declarations: [
    AppComponent,
    CategoryPipe,
    FilterPipe,
    ChatPipe,
    LocationTidyPipe,
    InfoComponent,
    ContactComponent,
    AdComponent,
    AdpageComponent,
    SortPipe,
    ItemIdPipe,
    LoginComponent,
    UserFormComponent,
    DropZoneDirective,
    FileUploadComponent,
    FileSizePipe,
    UnreadPipe,
    ActionComponent,
  //  ItemsComponent,
    AddItemComponent,
    PrivacyComponent,
    BiddingFormComponent,
    BidsTableComponent,
    UserActivityComponent,
    MessengerComponent,
    CatGalleryComponent,
    SubcatGalleryComponent,
    MyItemComponent,
    MyBidTableComponent,
    UserActivitySummaryComponent,
    UserRatingsComponent,
    AlertsComponent,
    VerifyPhoneComponent,
    UpgradeUserComponent,
    ScrollableDirective,
    MessengerChatComponent,
    ImgUploadResizeComponent,
    ProfilePublicComponent,
    UserSummaryComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    OrderModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    SweetAlert2Module.forRoot({
   //   buttonsStyling: false,
  //    customClass: 'modal-content',
  //    confirmButtonClass: 'btn btn-primary',
  //    cancelButtonClass: 'btn'
  }),
      MatSelectModule,
      MatFormFieldModule,
      NgxMatSelectSearchModule,
      MatInputModule,
      MatTooltipModule,
      MatSliderModule,
      StarRatingModule.forRoot(),
      TextareaAutosizeModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    LyThemeModule.setTheme('minima-light'), // or minima-dark 
    LyResizingCroppingImageModule,
   // AngularFirePerformanceModule,
    RouterModule.forRoot([{
             path: '',
             component: AppComponent
         }]),
  ],
  providers: [CookieService, WindowService, ItemService, 
  PaymentSingleService, AngularFireFunctions, MessagingService,
  PaginationService, ModalService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
  { provide: LY_THEME, useClass: MinimaLight, multi: true },
  { provide: LY_THEME, useClass: MinimaDark, multi: true },
  { 
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => AddItemComponent),
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
