import { Component, OnInit, Input } from '@angular/core';
import { WindowService } from '../../services/window.service';
import * as firebase from 'firebase';
import { AuthService } from '../../services/auth.service';

export class PhoneNumber {
  country: string;
//  area: string;
//  prefix: string;
//  line: string;
  main: string;

  // format phone numbers as E.164
  get e164() {
   // const num = this.country + this.area + this.prefix + this.line
   const num = this.country + this.main;
    return `+${num}`
  }

}

@Component({
  selector: 'verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: []
})
export class VerifyPhoneComponent implements OnInit {

  windowRef: any;
  phoneNumber = new PhoneNumber();
  verificationCode: string;
  @Input()
  user: any;

  constructor(private win: WindowService, private auth: AuthService) { }

  ngOnInit() {
    this.phoneNumber.country = '44';
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')
    this.windowRef.recaptchaVerifier.render();
  }


  sendLoginCode() {
    const appVerifier = this.windowRef.recaptchaVerifier;
    const num = this.phoneNumber.e164;
    firebase.auth().currentUser.linkWithPhoneNumber(num, appVerifier)
            .then(result => {
                this.windowRef.confirmationResult = result;
                this.auth.toastr.success('Activation code sent.');
            })
            .catch( error => console.log(error) );

  }

  verifyLoginCode() {
    this.windowRef.confirmationResult
                  .confirm(this.verificationCode)
                  .then( result => {
                    this.auth.verifyPhone();
                    this.auth.toastr.success('Phone number verified!');
    })
    .catch( error => console.log(error, "Incorrect code entered?"));
  }


}