import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'subcat-gallery',
  templateUrl: './subcat-gallery.component.html',
  styleUrls: ['./subcat-gallery.component.css']
})
export class SubcatGalleryComponent implements OnInit {

  @Input()
  cat: any;
  @Input()
  user: any;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  getAdsCat(subcatid, postcode){
    window.scroll(0,0);
     this.router.navigateByUrl('?searchcat='+this.cat.id+'-'+subcatid+'&searchpc='+postcode+'&searchdis=50');
   }

}
