import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { FileSizePipe } from '../../pipes/file-size.pipe';
import { tap ,  finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

declare var jQuery: any;
declare var ImageTools: any;

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html'
})
export class FileUploadComponent {
  downloadURL: Observable<any>;
  uploadState: any;
  ref: any;
  task: any;
  uploadProgress: any;
  loading: boolean;

  @Input() user: any;
  @Input() uid: any;
  @Input() type: any;

  @Output() newImg = new EventEmitter<any>();

  imgURL: any;
  uploadPercent: any;
  uploadURL: Observable<any>;
  isHovering: any;
  toggleHover: any;
  startUpload: any;


  constructor(private storage: AngularFireStorage, private afs: AngularFirestore,
    public toastr: ToastrService) { }

  ngOnInit(): void {
    this.loading = false;
    if (this.uid === undefined){
      this.uid = this.user.uid;
      }
    const filePath = this.type+`photos/`+this.uid+`/${new Date().getTime()}`;
    const ref = this.storage.ref(filePath);
  }
  
  upload(event) {
    const file = event.target.files[0];
    this.getOrientation(file, (orientation) => {
    this.getBase64(file, orientation);
    });
  }


  getOrientation(file, callback) {

    var reader:any,
    target:EventTarget;
    reader = new FileReader();
    reader.onload = (event) => {

      var view = new DataView(event.target.result);

      if (view.getUint16(0, false) != 0xFFD8) return callback(-2);

      var length = view.byteLength,
        offset = 2;

      while (offset < length) {
        var marker = view.getUint16(offset, false);
        offset += 2;

        if (marker == 0xFFE1) {
          if (view.getUint32(offset += 2, false) != 0x45786966) {
            return callback(-1);
          }
          var little = view.getUint16(offset += 6, false) == 0x4949;
          offset += view.getUint32(offset + 4, little);
          var tags = view.getUint16(offset, little);
          offset += 2;

          for (var i = 0; i < tags; i++)
            if (view.getUint16(offset + (i * 12), little) == 0x0112)
              return callback(view.getUint16(offset + (i * 12) + 8, little));
        }
        else if ((marker & 0xFF00) != 0xFF00) break;
        else offset += view.getUint16(offset, false);
      }
      return callback(-1);
    };

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  };

  getBase64(file, orientation) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var base64 = reader.result;
      this.resetOrientation(base64, orientation, (resetBase64Image) => {
        this.dataURItoBlob(resetBase64Image);
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  dataURItoBlob(dataURI) {
  
    // convert base64 to raw binary data held in a string
    var byteString = atob(dataURI.split(',')[1]);

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var bb = new Blob([ab], { type: "image/jpeg" });
    ImageTools.resize(bb, { width: 800, height: 800 }, ((blob, didItResize) => {
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = (() => {
        var base64data = reader.result;
        this.uploadImage(base64data);

        ImageTools.resize(bb, { width: 300, height: 300 }, ((blob, didItResize) => {
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = (() => {
            var base64data = reader.result;
   //         this.uploadIssueThumbnail(base64data);
          });
        }));
      });
    }));
    return bb;
  }

  resetOrientation(srcBase64, srcOrientation, callback) {
    var img = new Image();

    img.onload = () => {
      var width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
        case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
        case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
        case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
        case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
        case 7: ctx.transform(0, -1, -1, 0, height, width); break;
        case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
        default: break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      callback(canvas.toDataURL());
    };

    img.src = srcBase64;
  }

uploadImage(base64data){
    this.loading = true;
    const filePath = this.type+`photos/`+this.uid+`/${new Date().getTime()}`;

    var metadata = {
      contentType: 'image/jpeg',
      cacheControl: "public, max-age=31536000",
    };

    const ref = this.storage.ref(filePath);
    const task = ref.putString(base64data, 'data_url', metadata).then(() => {
      this.downloadURL = ref.getDownloadURL();
      this.setImg();
      this.loading = false;
    });


}

  confirmUpload(){
    if (this.uid === undefined){
      this.uid = this.user.uid;
      }
    const bg = jQuery('#new-profile-img').css('background-image');
    const url = bg.replace('url(','').replace(')','').replace(/\"/gi, "");
    this.afs.collection('users').doc(this.uid).update( { photoURL : url})
    this.toastr.success('Your image has been uploaded!', 'New profile image');
    this.task = undefined;
    this.downloadURL = null;
    this.uploadPercent = null;
    jQuery( "#profileImg" ).click();
  }

  cancelUpload(){
  this.downloadURL = null;
  this.uploadPercent = null;
  this.task = null;
  jQuery( "#profileImg" ).click();
  }

  newUpload(){
    this.downloadURL = null;
    this.uploadPercent = null;
    this.task = null;
    if (this.type === 'item' || this.type === 'issue') {
      this.newImg.emit();
    }
    }

  setImg(){
      this.downloadURL
     .subscribe(val =>
      this.newImg.emit(val));
    }

}
