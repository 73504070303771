import { Component, OnInit, Input, OnChanges, HostListener, SimpleChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { ModalService } from '../../services/modal.service';
import { HttpClient } from '@angular/common/http';
import { Item } from '../../models/Item';

declare var jQuery:any;

@Component({
  selector: 'user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.css']
})
export class UserActivityComponent implements OnInit, AfterViewInit {

  @Input()
  user: any;
  handler: any;
  tokenCreated: any;
  myItemsConfirmed: Item[];
  myItemsAccepted: Item[];
  myItemsListed: Item[];
  myItemsCompleted: Item[];
  myBids: any;
  myItems: any;
  myItemCount: number;
  myJobsConfirmed: any;
  myJobsAccepted: any;
  myJobsCompleted: any;
  myJobCount: any;
  readonly LOCAL_URL = 'assets/data/';
  ratings: Object;
  showLoading: boolean;
  hasActivebids: boolean;
  subscriptions: any;

  constructor(private itemService: ItemService, private http: HttpClient, public modal: ModalService) { }

  ngOnInit() {
    this.showLoading = true;
    this.getItems(this.user.uid);
    jQuery('#myItems-modals-container').html('');
    jQuery('#myItems-modals').appendTo('#myItems-modals-container');
    this.http.get(this.LOCAL_URL + '/ratings.json')
    .subscribe(data => {
      this.ratings = data;
    });
  }

ngOnChanges(changes: SimpleChanges): void {
 this.getItems(this.user.uid);
 this.countItems();
}

ngAfterViewInit(){
  this.countItems();
}

 openMyItem(i){
  jQuery('.modal').modal('hide');
  jQuery('#adModal-myItems-' + i).modal('show');
 }

 openMyBid(i){
  jQuery('.modal').modal('hide');
  jQuery('#adModal-myBids-' + i).modal('show');
 }

 revokeAccept(item){
   this.itemService.revokeAccept(item);
 }

openChat(item) {
    jQuery('.modal').modal('hide');
    jQuery('#openChat-' + item.id).click();
}

getItems(uid){
  this.subscriptions = [];
  this.myItemsConfirmed = [];
  this.subscriptions.push
  (this.itemService.getMyItems(uid, 'confirmed').valueChanges()
  .subscribe((data) => {
    this.myItemsConfirmed = data;
    this.countItems();
  }));
  this.myItemsAccepted = [];
  this.subscriptions.push
  (this.itemService.getMyItems(uid, 'accepted').valueChanges()
  .subscribe((data) => {
    this.myItemsAccepted = data;
    this.countItems();
  }));
  this.myItemsListed = [];
  this.subscriptions.push
  (this.itemService.getMyItems(uid, 'listed').valueChanges()
  .subscribe((data) => {
    this.myItemsListed = data;
    this.countItems();
  }));
  this.myItemsCompleted = [];
  this.subscriptions.push
  (this.itemService.getMyItems(uid, 'completed').valueChanges()
  .subscribe((data) => {
    this.myItemsCompleted = data;
    this.countItems();
  }));
  this.myJobsConfirmed = [];
  this.subscriptions.push
  (this.itemService.getMyJobs(uid, 'confirmed').valueChanges()
  .subscribe((data) => {
    this.myJobsConfirmed = data;
    this.countItems();
  }));
  this.myJobsAccepted = [];
  this.subscriptions.push
  (this.itemService.getMyJobs(uid, 'accepted').valueChanges()
  .subscribe((data) => {
    this.myJobsAccepted = data;
    this.countItems();
  }));
  this.myJobsCompleted = [];
  this.subscriptions.push
  (this.itemService.getMyJobs(uid, 'completed').valueChanges()
  .subscribe((data) => {
    this.myJobsCompleted = data;
    this.countItems();
    this.showLoading = false;
  }));
  this.myBids = [];
  this.itemService.getMyBids(uid);
  this.myBids = this.itemService.page.data;
}

countItems(){
  this.myItemCount = (this.myItemsConfirmed.length + this.myItemsAccepted.length + this.myItemsListed.length);
  this.myJobCount = (this.myJobsConfirmed.length + this.myJobsAccepted.length + this.myJobsCompleted.length);
  jQuery('#activityModal .tab-content .tab-pane').removeClass('active');
  jQuery('#activityModal .nav-tabs li').removeClass('active');
  jQuery('#activityModal .tab-content .tab-pane:first-of-type').addClass('active');
  jQuery('#activityModal .nav-tabs li:first-of-type').addClass('active');
}

ngOnDestroy() {
  if (this.subscriptions > 0){
    this.subscriptions.unsubscribe();
  }
  if (this.myBids > 0){
    this.myBids.unsubscribe(); 
  }
}

// @HostListener('window:popstate')
//  onPopstate() {
//    this.handler.close();
//  }

}
