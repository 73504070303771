// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripeKey: 'pk_test_mJ2BBGMS7ZNo4PYvPUuXaFZc',
  stripePublishable: 'pk_test_Ys9dfQqKFtOFU5L9tEBFtQdb',
  functionsURL: "https://us-central1-repair-we.cloudfunctions.net",
  firebaseConfig:
  {
    apiKey: "AIzaSyB-pNybk-KB8-5pDh-VxmiP8rvFiRObff8",
    authDomain: "repair-we.firebaseapp.com",
    databaseURL: "https://repair-we.firebaseio.com",
    projectId: "repair-we",
    storageBucket: "repair-we.appspot.com",
    messagingSenderId: "942113824851",
    appId: "1:942113824851:web:a2ead6786358adaf"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
