import { Component, Input, OnChanges, ViewChild, ElementRef, OnInit } from '@angular/core';
import { MessagingService } from '../../services/messaging.service';
import { ChatPipe } from '../../pipes/chat.pipe';
import { Observable } from 'rxjs/Observable';
import { interval, Subscription } from 'rxjs';
import 'rxjs/add/observable/interval';

declare var jQuery:any;

@Component({
  selector: 'messenger-chat',
  templateUrl: './messenger-chat.component.html',
  styleUrls: ['./messenger-chat.component.css']
})
export class MessengerChatComponent implements OnInit {
  @ViewChild('chatContents') private chatContents: ElementRef;

  @Input()
  user: any;
  @Input()
  chat: any;

  text: string;
  sender: any;
  recipient: any;
  chatMessages: any;
  hideModal: boolean;
  searchText: string;
  scrolled: boolean;
  checkScroll: any;
  showScrollButton: boolean;

  constructor(public msg: MessagingService) {}

  ngOnInit() {
    this.getMessages();
  }

  sendMessage() {
    this.msg.sendMessage(this.text, this.chat.id, this.user.uid, this.chat.contact.uid);
    this.text = '';
  }

  scrollToBottom(): void {
    try {
        this.chatContents.nativeElement.scrollTop = this.chatContents.nativeElement.scrollHeight;
    } catch(err) { }
}

  scrollHandler(e) {
    if (e === 'top') {
      this.msg.page.more();
    }
  }

  getMessages() {
        this.msg.getChatMessages(this.chat);
        this.msg.setToRead(this.chat, this.user.uid);
  }

  showTime(time){
    const showTime = this.msg.convertTime(time);
    return showTime;
  }

}
