import { Component, ViewChild, Inject, Renderer2, ElementRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LyTheme2, ThemeVariables } from '@alyle/ui';
import { LyResizingCroppingImages, ImgCropperConfig, ImgCropperEvent } from '@alyle/ui/resizing-cropping-images';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';

import { ToastrService } from 'ngx-toastr';

declare var jQuery: any;
declare var ImageTools: any;

@Component({
  selector: 'img-upload-resize',
  templateUrl: './img-upload-resize.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImgUploadResizeComponent  {
  @Input()
  user;
  @Input()
  type;
  croppedImg: string;
  @ViewChild(LyResizingCroppingImages) imgCropper: LyResizingCroppingImages;
  scale: number;
  myConfig: ImgCropperConfig = {
    width: 200,
    height: 200,
    fill: '#ff2997'
  };
  loading: boolean;
  downloadURL: any;
  uploadPercent: any;
  

  constructor(
    private theme: LyTheme2,
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private toastr: ToastrService
  ) { }

  /** on event */
  onCrop(e: ImgCropperEvent) {
    this.croppedImg = e.dataURL;
    console.log(e);
  }
  /** manual crop */
  getCroppedImg() {
    const img = this.imgCropper.crop();
    console.log(img);
    return img.dataURL;
  }

  upload(event) {
    this.uploadImage(this.croppedImg);
  }

uploadImage(base64data){
    this.loading = true;
    const containingFilePath = this.type+`photos/`+this.user.uid;
    const filePath = this.type+`photos/`+this.user.uid+`/${new Date().getTime()}`;

    var metadata = {
      contentType: 'image/jpeg',
      cacheControl: "public, max-age=31536000",
    };
  //  this.storage.ref(containingFilePath).delete(); NOT YET AVAILABLE
    const ref = this.storage.ref(filePath);
    const task = ref.putString(base64data, 'data_url', metadata).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((url) => {
        this.loading = false;
        this.updatePhotoUrl(url);
      });
  });


}

  updatePhotoUrl(url){
     this.afs.collection('users').doc(this.user.uid).update( { photoURL : url})
    this.toastr.success('Your image has been uploaded!', 'New profile image');
    this.downloadURL = null;
    this.imgCropper.clean();
    this.croppedImg = null;
    this.uploadPercent = null;
    jQuery('#collapsePhoto').collapse();
    jQuery("html, body").animate({ scrollTop: 0 }, 500);
  }

}
