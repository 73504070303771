import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocationTidyPipe } from '../../pipes/locationtidy.pipe';

@Component({
  selector: 'advert',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.css']
})
export class AdComponent implements OnChanges {

  @Input()
  ad: any;
  @Input()
  categories: any;
  @Input()
  conditions: any;
  @Input()
  location: any;
  @Input()
  preview: boolean;
  @Input()
  img: string;
  @Input()
  user: any;

  distance: any;
  place: any;

  constructor(private http: HttpClient) { }

ngOnInit(){
     this.setLocation();
  }

  ngOnChanges(changes: SimpleChanges){
      this.setImg();
      this.setLocation();
  }

  setImg(){
    if (this.ad.img){

    } else if (this.img) {
      this.ad.img = this.img;
    } else if (this.ad.category) {
      this.ad.img = 'assets/img/categories/'+this.ad.category+'.jpg'
    } else {
      this.ad.img = '';
    }
  }

  setLocation(){
    if (this.ad.place) {
    } else if (this.ad.lng && this.ad.lat) {
    const itemlng = this.ad.lng;
    const itemlat = this.ad.lat;
    const LOCATION_URL = 'https://api.postcodes.io/postcodes?lon='+itemlng+'&lat='+itemlat+'?limit=1'
    this.http.get(LOCATION_URL)
    .subscribe(
      data => {
      this.ad.location = data;
      this.ad.getPlace = this.ad.location.result[0].parish;
      });
    }
  }

}
