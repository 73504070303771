import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/scan';
import 'rxjs/add/operator/take';

interface QueryConfig {
  opts: any;
  path: string; //  path to collection
  field: string; // field to orderBy
  limit: number; // limit per query
  reverse: boolean; // reverse order?
  prepend: boolean; // prepend to source?
}

@Injectable()
export class PaginationService {

  // Source data
  private _done = new BehaviorSubject(false);
  private _loading = new BehaviorSubject(false);
  private _data = new BehaviorSubject([]);

  private query: QueryConfig;

  // Observable data
  data: Observable<any>;
  done: Observable<boolean> = this._done.asObservable();
  loading: Observable<boolean> = this._loading.asObservable();

  constructor(private afs: AngularFirestore) { }

  // Initial query sets options and defines the Observable
  // passing opts will override the defaults
  init(path: string, field: string, opts?: any) {
    this.reset();
    this.query = {
      path,
      field,
      limit: opts.limit,
      reverse: false,
      prepend: false,
      ...opts
    };
    
    const first = this.afs.collection(this.query.path, ref => {
       return ref
      .where(this.query.opts.findValue, this.query.opts.conditionType, this.query.opts.matchValue)
      .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
      .limit(this.query.opts.limit);
    });

    this.mapAndUpdate(first);

    // Create the observable array for consumption in components
    this.data = this._data.asObservable()
    .scan((acc, val) => {
    let items = [];
    val.forEach(v => {
    for (let index = 0; index < acc.length; index++) {
    const a = acc[index];
    if (a.doc.id === v.doc.id) {
      v.doc_id=a.doc.id;
    acc[index] = v; // replace the current doc with the updated doc.
    return;
    }
    }
    items.push(v); // if the doc is not found from the current list, append it
    });
    return this.query.prepend ? items.concat(acc) : acc.concat(items);
    })
  }


  // Retrieves additional data from firestore
  more() {
    const cursor = this.getCursor();

    const more = this.afs.collection(this.query.path, ref => {
      return ref
              .where(this.query.opts.findValue, this.query.opts.conditionType, this.query.opts.matchValue)
              .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
              .limit(this.query.opts.limit)
              .startAfter(cursor);
    });
    this.mapAndUpdate(more);
  }

  // Determines the doc snapshot to paginate query
  private getCursor() {
    const current = this._data.value;
    if (current.length) {
      return this.query.prepend ? current[0].doc : current[current.length - 1].doc;
    }
    return null;
  }


  // Maps the snapshot to usable format the updates source
  private mapAndUpdate(col: AngularFirestoreCollection<any>) {

    if (this._done.value || this._loading.value) { return; }

    // loading
    this._loading.next(true);

    // Map snapshot with doc ref (needed for cursor)
    return col.snapshotChanges()
      .do(arr => {
        let values = arr.map(snap => {
          const docid=snap.payload.doc.id;
          const data = snap.payload.doc.data();
          data.doc_id=docid;
          const doc = snap.payload.doc;
          return { ...data, doc };
        });

        // If prepending, reverse the batch order
        values = this.query.prepend ? values.reverse() : values;

        // update source with new values, done loading
        this._data.next(values);
        this._loading.next(false);

        // no more values, mark done
        if (!values.length) {
          this._done.next(true);
        }
    })
   // .take(1)
    .subscribe();

  }

  reset() {
    this._data = new BehaviorSubject([]);
    this._loading = new BehaviorSubject(false);
    this._done = new BehaviorSubject(false);
  }

}