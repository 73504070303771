import { Component, OnInit, Input } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'user-ratings',
  templateUrl: './user-ratings.component.html',
  styleUrls: ['./user-ratings.component.css']
})
export class UserRatingsComponent implements OnInit {

  @Input()
  user: any;
  myRatings: any;
  readonly LOCAL_URL = 'assets/data/';
  questions: any;
  scores: any;
  allReviewsAvg: number;
  allReviewsTotal: number;
  allReviewsArray: any;
  ratedUser: any;

  constructor(public itemService: ItemService, public http: HttpClient) { }

  ngOnInit() {
  this.http.get(this.LOCAL_URL + '/ratings.json')
  .subscribe(data => {
    this.questions = data[0].questions;
    this.scores = data[0].scores;
  });
  this.ratedUser = this.user;
    this.itemService.getMyRatings(this.user.uid).valueChanges()
    .subscribe((data) => {
      this.myRatings = data;
      this.allReviewsArray = [];
      this.allReviewsTotal = 0;
      for (let review of this.myRatings){
        this.itemService.getUser(review.reviewer).valueChanges().subscribe((data) => {
          review.reviewer = data;
          review.ratingsArray = [];
          review.total = 0;
          for (let rating of review.ratings){
            if (rating.id !== 'comments') {
            review.total = review.total + (+rating.value);
            review.ratingsArray.push(rating.value)
          }
          }
          review.avg = (review.total / review.ratingsArray.length);
          this.allReviewsTotal = this.allReviewsTotal + (+review.avg);
          this.allReviewsArray.push(+review.avg);
      //  review.reviewerAge = this.itemService.getAge(this.customer.date);
         });
      }
    });
    this.ratedUser.rating = this.ratedUser.ratingTotal / this.ratedUser.ratingCount;
  }
  
}
