import { Component, OnInit, Input } from '@angular/core';
import { PaymentSingleService } from '../../services/payment_single.service';
import { environment } from '../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'upgrade-user',
  templateUrl: './upgrade-user.component.html',
  styleUrls: ['./upgrade-user.component.css']
})
export class UpgradeUserComponent implements OnInit {

  @Input()
  user: any;
  handler: any;
  amount = 3000;
  tokenCreated:any;

  constructor(private auth: AuthService, private paymentSvc: PaymentSingleService) { }

  ngOnInit() {
    this.handler = StripeCheckout.configure({
      key: environment.stripeKey,
      locale: 'auto',
      token: token => {
        this.paymentSvc.processPayment(token, this.amount);
        this.tokenCreated = token.created;
        this.auth.upgradeUser(this.user.id);
      }
    });
  }

  upgrade(email) {
    this.handler.open({
      name: 'Upgrade to premium',
      description: '1 year subscription',
      image: '/assets/img/icon_solid.png',
      currency: 'gbp',
      amount : this.amount,
      email: email
    });
  }

}
