import { Component, Input, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MessagingService } from '../../services/messaging.service';
import { ModalService } from '../../services/modal.service';
import { ChatPipe } from '../../pipes/chat.pipe';
import { Observable } from 'rxjs/Observable';
import { interval, Subscription } from 'rxjs';
import 'rxjs/add/observable/interval';
import { DOCUMENT } from '@angular/common';


declare var jQuery:any;

@Component({
  selector: 'messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.css']
})
export class MessengerComponent implements OnInit {
  @ViewChild('chatContents') private chatContents: ElementRef;

  @Input()
  user: any;
  @Input()
  chats: any;
  @Input()
  selectedChat: any;

  text: string;
  sender: any;
  recipient: any;
  chatMessages: any;
  hideModal: boolean;
  searchText: string;
  scrolled: boolean;
  checkScroll: any;
  initChats: any;

  constructor(private msg: MessagingService, @Inject(DOCUMENT) private document: any, public modal: ModalService) {}

ngOnInit() {
    this.initChats = [];
}

  selectChat(selectedChat) {
    this.msg.page.reset();
    this.selectedChat = selectedChat;
    for (let chat of this.chats) {
      if (selectedChat.id === chat.id) {
        this.initChats.push(chat.id);
      }
    }
  }

  itemDetails(item) {
    this.msg.itemDetails(item, this.user.uid);
  }

  unselectChat() {
    this.selectedChat = null;
  }

}
