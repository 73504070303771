import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { Subject } from 'rxjs/Subject';
import { Message } from '../models/message';
import { tap, switchMap, map } from 'rxjs/operators';
import { pipe, Observable } from 'rxjs';
import { PaginationService } from '../services/pagination.service';
import { ModalService } from '../services/modal.service';
import { ItemService } from '../services/item.service';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { floor } from 'lodash';

declare var jQuery:any;

@Injectable()
export class MessagingService {

  messages?: any;
  messagesRef: any;
  messagesLatest: any;
  messagesCollection: AngularFirestoreCollection<Message>;
  convdataTime: string;
  requirement: string;
  requirementCompleted: string;
  viewItem: any;

  constructor(private afs: AngularFirestore, public page: PaginationService, private modal: ModalService, private itemService: ItemService) {}

newMessage(uid, time, message){
  const messageContent = {
    id: time,
    title: message.title,
    body: message.body,
    linkUrl: message.linkUrl,
    important: message.important,
    read : false,
}
  this.messages = this.afs.doc(`/messages/${uid}`).collection('admin').doc(time).set(messageContent);
}

getChatsLatest(uid){
  const chats = this.afs.collection(`/users/${uid}/chats`, ref => ref.orderBy('lastUpdated', 'desc').limit(21) );
  return chats;
}

getAlertsLatest(uid){
  this.messages = this.afs.collection(`/users/${uid}/alerts`, ref => ref.orderBy('time', 'desc').limit(5));
  return this.messages;
}
deleteAlert(uid,alert){
  const unix=alert.time;
  const alertDoc = this.afs.firestore.doc(`/users/${uid}/alerts/${unix}`);
  const batch = this.afs.firestore.batch();
  batch.delete(alertDoc);
  batch.commit();
}
setAlertSeen(uid,alert){
  const unix=alert.time;
  const alertDoc = this.afs.firestore.doc(`/users/${uid}/alerts/${unix}`);
  const statsDoc = this.afs.firestore.doc(`/users/${uid}/alerts/--stats--`);
  const batch = this.afs.firestore.batch();
  batch.set(alertDoc, alert);
  batch.set(statsDoc, { alertCount: 0 }, { merge: true });
  batch.commit();
}

getAlerts(uid){
  this.page.init(`/users/${uid}/alerts`, 'time', { reverse: true, prepend: false, opts:{findValue: 'time', conditionType: '>', matchValue: 0, limit:7} });
}

getAlertStats(uid) {
  const statsDoc = this.afs.doc(`/users/${uid}/alerts/--stats--`);
  return statsDoc;
}

readAlert(url, uid, time){
  if (url === 'myjobs'){
    this.modal.open('#activityModal');
    this.modal.openTab('#jobs');
   } else if (url === 'myitems') {
    this.modal.open('#activityModal');
    this.modal.openTab('#items');
   } else if (url.includes('listedItem')) {
     const itemID = url.split('|')[1];
     if (itemID){
     this.itemService.getSingleItem(itemID)
     .valueChanges().subscribe((data) => {
        this.modal.ad = data;
        this.modal.open('#adModal-viewItem');
     })
     }
  }
   else {
    // go to URL: need router if used
   }
   const alertDoc = this.afs.firestore.doc(`/users/${uid}/alerts/${time}`);
   const statsDoc = this.afs.firestore.doc(`/users/${uid}/alerts/--stats--`);
   const decrement = firebase.firestore.FieldValue.increment(-1);
   const batch = this.afs.firestore.batch();
// commented out read function to allow checking of opening
   //   batch.delete(alertDoc);
//   batch.set(statsDoc, { alertCount: decrement }, { merge: true });
//   batch.commit();
}

sendMessage(text, chatId, sender, recipient){
 // const newDate = new Date();
  const time = new Date();
  const unix =  +time / 1000;
  const MessageDoc = this.afs.doc(`chats/${chatId}/messages/${unix}`);
  const message = {
    'uid' : sender,
    'body' : text,
    'time' : unix,
    };
    MessageDoc.set(message);
    const senderChatDoc =
    this.afs.doc(`users/${sender}/chats/${chatId}`);
    const recipientChatDoc =
    this.afs.doc(`users/${recipient}/chats/${chatId}`);
    const senderChat = {
      lastMessage : message,
      lastUpdated : unix,
      unread : false,
      };
    const recipientChat = {
        lastMessage : message,
        lastUpdated : unix,
        unread : true,
        };
    senderChatDoc.update(senderChat);
    recipientChatDoc.update(recipientChat);
}

setToRead(chat, uid){
  const userChat = this.afs.doc(`users/${uid}/chats/${chat.item.id}-${chat.repairer}`);
  const setRead = {unread : false};
  userChat.update(setRead);
}

getContact(uid){
  const contact = this.afs.doc(`users/${uid}`);
  return contact;
}

getChatMessages(chat){
  this.page.init('chats/' + chat.id + '/messages', 'time', { reverse: true, prepend: false, opts: {findValue: 'time', conditionType: '>', matchValue: 0, limit:10} });
}

itemDetails(item, uid){
  if (item.listingtype ==='f'){
    this.requirement = 'fix';
    this.requirementCompleted = 'fixed';
  } else if (item.listingtype ==='d') {
    this.requirement = 'diagnosis';
    this.requirementCompleted = 'diagnosed';
  }
  Swal.fire({
    html:
    "<div class='popup-item-img' style='background-image:url(" + item.img + ")'></div><h2>" + item.title + "<br>£" + item.winningBid + " accepted for "+this.requirement+"</h2><br>"+item.description,
    confirmButtonText:
   // 'Mark as '+ this.requirementCompleted,
   'Open item',
    confirmButtonAriaLabel:
   // 'Mark as '+ this.requirementCompleted,
   'Open item',
    showCancelButton: true,
    cancelButtonText:
    'Close',
    cancelButtonAriaLabel: 'Close',
  }).then((result) => {
    if (result.value) {
      this.modal.open('#activityModal');
      if (item.winningBidder === uid){
        this.modal.openTab('#jobs');
      } else {
        this.modal.openTab('#items');
      }
  //    jQuery('#complete-'+item.id).click();
    }
  })
  
}

convertTime(unix){

  // Unixtimestamp
  const unixtimestamp = unix;
 
  // Months array
  const months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
 
  // Convert timestamp to milliseconds
  const date = new Date(unixtimestamp*1000);
 
  // Year
  const year = date.getFullYear();
 
  // Month
  const month = months_arr[date.getMonth()];
 
  // Day
  const day = date.getDate();
 
  // Hours
  const hours = date.getHours();
 
  // Minutes
  const minutes = "0" + date.getMinutes();

  const currentTime = new Date();
  const currentYear = currentTime.getFullYear()
  const currentMonth = months_arr[currentTime.getMonth()];
  const currentDay = currentTime.getDate();
 
  // Display date time in MM-dd-yyyy h:m:s format

  if (day === currentDay){
  this.convdataTime = hours + ':' + minutes.substr(-2);
  } else if (year === currentYear){
  this.convdataTime = day+' '+month;
  } else {
  this.convdataTime = day+' '+month+' '+year+' '+hours + ':' + minutes.substr(-2);
  }
  return this.convdataTime;

 }

 getAge(itemDate){
  const time = (+ new Date / 1000);
  const rectime = (+itemDate / 1000);
  const age = (time - rectime);
  const secondsOld = floor(age);
  const daysOld = floor(secondsOld / (3600 * 24));
  const hoursOld = floor(secondsOld / 3600);
  const minutesOld = floor(secondsOld / 60);
  const weeksOld = floor(daysOld / 7);
  const monthsOld = floor(weeksOld / 4.34524);
  const yearsOld = floor(daysOld / 365.2422);
  if (weeksOld >= 1) {
    return (weeksOld+'w').toString();
  }
  else if (daysOld >= 1) {
    return (daysOld+'d').toString();
  }
  else if (hoursOld >= 1){
    return (hoursOld+'h').toString();
  }
  else if (minutesOld >= 1){
    return (minutesOld+'m').toString();
  }
  else {
    return '0m';
  }
}

}