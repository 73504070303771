import { Component, OnInit, Input, ViewChild, HostListener, AfterViewChecked } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { ItemService } from '../../services/item.service';
import { PaymentSingleService } from '../../services/payment_single.service';
import { Item } from '../../models/Item';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { environment } from '../../environments/environment';
import { groupBy } from 'rxjs/operators';
import { LocationTidyPipe } from '../../pipes/locationtidy.pipe';
import { floor } from 'lodash';
import { FilterPipe } from '../../pipes/filter.pipe';

declare var jQuery:any;

@Component({
  selector: 'add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.css'],
})
export class AddItemComponent implements OnInit, AfterViewChecked {

  handler: any;
  amount = 250;
  item: Item = {
    status:'listed'
  }


  location:any;
  img:any;
  imgIssue:any;
  postcodeInvalid: boolean;
  email:any;
  tokenCreated:any;

  @Input() categories: any;
  @Input() issues: any;
  @Input() conditions: any;
  @Input() user: any;
  @Input() newImg: any;

  @ViewChild('uploadBox') uploadBox;
  @ViewChild('uploadBoxIssue') uploadBoxIssue;
  listItemForm: FormGroup;
  urgentListing: boolean;
  issueFilterCtrl: any;
  preview: any;

  constructor(private itemService: ItemService, public auth: AuthService, private http: HttpClient,
    private paymentSvc: PaymentSingleService, public fb: FormBuilder, public modal: ModalService) {}

  ngOnInit(){
  this.resetItem();
  this.img='';
  this.imgIssue='';
  this.postcodeInvalid = false;
  jQuery('a').tooltip();

  this.handler = StripeCheckout.configure({
    key: environment.stripeKey,
    locale: 'auto',
    token: token => {
      this.paymentSvc.processPayment(token, this.amount);
      this.tokenCreated = token.created;
      this.submitItem();
    }
  });

  this.listItemForm = this.fb.group({
    'title': ['', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(32),
    ]],
    'description': ['', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(100),
    ]],
    'category': ['', [
      Validators.required,
    ]],
    'subcategory': ['', [
      Validators.required,
    ]],
    'catother': ['', []],
    'issue': ['', []],
    'issueFilterCtrl': ['', []],
    'listingtype': ['', [
      Validators.required,
    ]],
    'deposit': ['', []],
    'budget': ['', []],
    'postcode': ['', [
      Validators.required,
    ]],
    'urg': ['', []]
  });
}

get title() { return this.listItemForm.get('title')}
get description() { return this.listItemForm.get('description')}
get category() { return this.listItemForm.get('category')}
get deposit() { return this.listItemForm.get('deposit')}

ngAfterViewChecked() {
  jQuery('.modal .input-group input').focus(function() {
    jQuery(this).parent().addClass('input-group-focus');
  }).blur(function(){
    jQuery(this).parent().removeClass('input-group-focus');
  });
  }

  updatePreview(){
  this.preview.uid = 111;
  this.preview.title = this.listItemForm.get('title').value;
  this.preview.description = this.listItemForm.get('description').value;
  this.preview.category = this.listItemForm.get('category').value;
  if (this.location && !this.postcodeInvalid){
  this.preview.place = this.location.result.parish;
  }
  }

  updateImg($event){
      this.img = $event;
  }

  updateIssue($event){
      this.imgIssue = $event;
    }

  updateLocation(){
    const postcode = this.listItemForm.get('postcode').value;
    if (postcode.length > 4){
      const shortpc = postcode.replace(/\s/g, "");
      const SEARCH_LOCATION_URL = 'https://api.postcodes.io/postcodes/'+shortpc;
      if (SEARCH_LOCATION_URL) {
      this.http.get(SEARCH_LOCATION_URL)
      .subscribe(
        data => {
          this.location = data;
          this.postcodeInvalid = false;
          this.updatePreview();
        },
        err => this.postcodeInvalid = true
        );

    }
   }
  }


  private onSubmit(formData: any, formDirective: FormGroupDirective, email): void {
    if (this.listItemForm.valid && this.img && this.postcodeInvalid === false) {
    if (this.listItemForm.get('urg').value === true){
      this.handlePayment(email);
    } else {
    this.submitItem();
    formDirective.resetForm();
    this.listItemForm.reset();
  }
  } else {
    this.auth.toastr.error('Please make sure you have filled in all fields and set an image', 'Missing information', {timeOut: 8000} )
  }
  }

  submitItem(){
    this.updateLocation();
      const date = + new Date();
      const unix =  +floor(date / 1000);
      this.item = this.listItemForm.value;
      this.item.img = this.img;
      this.item.imgIssue = this.imgIssue;
      this.item.uid = this.user.uid;
      this.item.id = this.item.uid + '-' + unix;
      this.item.date = new Date().getTime();
      this.item.img = this.img;
      this.item.imgIssue = this.imgIssue;
      this.item.status = 'listed';
      if (this.tokenCreated) {
        this.item.paymentID = this.item.uid + '-' + this.tokenCreated;
      }
      if (this.listItemForm.get('urg').value === true){
        this.item.urg = true;
      }
      this.item.lng = this.location.result.longitude;
      this.item.lat = this.location.result.latitude;
      this.item.place = this.location.result.parish;
      this.itemService.addItem(this.item);
      this.uploadBox.newUpload();
      this.uploadBoxIssue.newUpload();
      jQuery('.modal').modal('hide');
      this.resetItem();
  }

  resetItem(){
    this.item = undefined;
    this.img = undefined;
    this.imgIssue = undefined;
    this.location = undefined;
    this.preview = [];
    this.preview.uid = 111;
;  }

  handlePayment(email) {
    this.handler.open({
      name: 'Submit urgent listing',
      description: 'Alert repairers about your item',
      image: '/assets/img/icons/urgent.png',
      currency: 'gbp',
      amount : this.amount,
      email: email
    });
  }

  filterIssues(value){
    this.issueFilterCtrl = value;
  }

  @HostListener('window:popstate')
    onPopstate() {
      this.handler.close();
    }

}
