import { Component, OnInit, AfterViewChecked, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { OrderPipe } from 'ngx-order-pipe';
import { UnreadPipe } from '../pipes/unread.pipe';
import { AuthService } from '../services/auth.service';
import { ItemService } from '../services/item.service';
import { MessagingService } from '../services/messaging.service';
import { ModalService } from '../services/modal.service';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/filter';
import * as Hammer from 'hammerjs';
import { ToastrService } from 'ngx-toastr';

import { Observable } from 'rxjs/Observable';

import { Item } from '../models/Item';

declare var jQuery:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css','../../node_modules/animate.css/animate.css',]
})
export class AppComponent implements OnInit {
  title = 'JustFixd';
  unseen=true;
  readonly LOCAL_URL = 'assets/data/';
  year: any;
  categories: any;
  conditions: any;
  ads: any;
  adlist: any;
  search: any;
  searchcat: string;
  searchcatselect: string;
  searchsubcat: string;
  searchsubcatselect: string;
  searchcon: any;
  searchconselect: any;
  searchage: any;
  searchageselect: any;
  searchdis: number;
  searchdisselect: number;
  searchurg: any;
  searchurgselect: any;
  searchlisttype: any;
  searchlisttypeselect: any;
  searchbid: any;
  searchver: any;
  searchpc: any;
  shortpc: any;
  postcodecheck: any;
  results: any;
  resultText: string;
  resultArray: any;
  resultTextFormatted: any;
  searchTerm: any;
  params: any;
  location: any;
  userdistancemetric: any;
  distance: any;
  selectedad: any;
  listbit: number;
  order: string = 'distance';
  resultlist: any;
  greeting: any;
  verfiy: any;
  filterActive: any;
  searchcategory: any;
  action: any;
  adDB: any;
  liveads: any;
  page: any;
  pageVersion: any;
  loaded: boolean;
  messages: any;
  uid: any;
  myBids: any;
  myBidsCompleted: number;
  myBidsActive: number;
  jobsCount: number;
  alerts: any;
  alertsdb: any;
  chats: any;
  previewChat: any;
  showSpinner: boolean;
  searchpcInput: string;
  searchText: string;
  searchcatsub: any;
  showLoading: boolean;
  searchissueselect: string;
  searchissue: string;
  userActivy: any;
  issues: Object;
  viewItem: any;
  user: any;
  showAlertsSpinner: boolean;
  alertCount: any;
  alertStats: unknown;
  referalLink: string;

  constructor(private http: HttpClient,
    private route: ActivatedRoute, private router: Router,
     private orderPipe: OrderPipe, public auth: AuthService, private toasr: ToastrService,
     private itemService: ItemService, public msg: MessagingService,
     public modal: ModalService){}

  ngOnInit(){
    this.showLoading = true;
    this.page = 'main';

    this.route.queryParams.subscribe((params)=> {
      //check lead Id here
      if(params['mode']){
        //console.log(params['mode']);
        this.action = true;
      } else {
       
      }
    });

    this.route.queryParams.subscribe((params)=> {
      //check lead Id here
      if(params['repair']){
        //console.log(params['mode']);
        this.pageVersion = 'r';
    };
    });

    if (this.router.url.indexOf('/privacy') > -1) {
      this.page = 'privacy';
    }

    this.route.queryParams.subscribe((params)=> {
      //check lead Id here
      if(params['signin']){
        this.openLoginModal();
     }
    });

        this.userdistancemetric = 'miles';

        this.listbit = 6;

        var d = new Date();
        this.year = d.getFullYear();

        var d = new Date();
        var time = d.getHours();

        this.greeting='Hello';

        if (time < 12) {
            this.greeting='Good morning';
        }
        if (time > 12) {
            this.greeting='Good afternoon';
        }
        if (time > 18) {
            this.greeting='Good evening';
        }

        var closeNav: any;

        this.http.get(this.LOCAL_URL + '/categories.json')
        .subscribe(data => this.categories = data);

        this.http.get(this.LOCAL_URL + '/conditions.json')
        .subscribe(data => this.conditions = data);

        this.http.get(this.LOCAL_URL + '/issues.json')
        .subscribe(data => this.issues = data);

       this.filterActive=0;

       this.route.queryParams.subscribe(params => {
         let value = this.route.snapshot.queryParams['searchpc'];
         this.searchpc = '';
         if (value){
          this.showLoading = false;
           this.searchpc = value;
           const shortpc = this.searchpc.replace(/\s/g, '');
           this.searchpcInput = value;
           const SEARCH_LOCATION_URL = 'https://api.postcodes.io/postcodes/'+shortpc;
           this.http.get(SEARCH_LOCATION_URL)
           .subscribe((data) => {
            this.location = data;
            // get search category
            this.route.queryParams.subscribe(params => {
              let value = this.route.snapshot.queryParams['searchcat'];
              if (value && !(value === undefined || value === null || value==='')){
                this.searchcatsub = value;
                const catsub = this.searchcatsub.split('-');
                this.searchcat = catsub[0];
                this.searchsubcat = catsub[1];
                this.searchsubcatselect = this.searchsubcat;
                this.searchcatselect = this.searchcat;
              this.showSpinner = true;
              this.filterActive = this.filterActive + 1;
            // get search max distance 
            this.route.queryParams.subscribe(params => {
              let value = this.route.snapshot.queryParams['searchdis'];
              if (value){
              this.searchdis = value;
              }
              this.searchdisselect = this.searchdis;
              if(this.searchdis<100){this.filterActive = this.filterActive + 1};
              const lat = this.location.result.latitude;
              const lng = this.location.result.longitude;
              const cat = this.searchcatsub;
              const issue = this.searchissue;
              const dis = this.searchdis;
              if (cat){
                this.itemService.getItems(lat, lng, cat, issue, dis)
                .subscribe((data) => {
                  this.showSpinner = false;
                  this.ads = data;
                  this.results = this.ads;
                });
              }
            })
               }
              else {this.searchcat=''};
           })
          });
        } else {
          this.searchpcInput = '';
        }
      })

       this.route.queryParams.subscribe(params => {
         let value = this.route.snapshot.queryParams['searchcon'];
          if (value && !(value === undefined || value === null || value==='')){
        this.searchcon = value
         this.filterActive = this.filterActive + 1;
          }
         else {this.searchcon=1};
         //console.log(this.searchcon);
         this.searchconselect = this.searchcon;
      })

      this.route.queryParams.subscribe(params => {
        let value = this.route.snapshot.queryParams['searchage'];
        if (value ){
        this.searchage = value
        }
        else {this.searchage=100};
        if(this.searchage<100){this.filterActive = this.filterActive + 1};
        //console.log(this.searchage);
        this.searchageselect = this.searchage;
     })

     this.route.queryParams.subscribe(params => {
      let value = this.route.snapshot.queryParams['searchissue'];
      if (value ){
      this.searchissue = value
      }
      else {this.searchissue = ''};
      this.searchissueselect = this.searchissue;
   })

    this.auth.afAuth.authState.subscribe(user => {
      if(user) {
      this.showLoading = false;
      this.user = user;
      this.uid = user.uid;
      this.msg.getChatsLatest(this.uid).valueChanges()
      .subscribe((data) => {
          this.chats = data;
          for (let chat of this.chats){
            if (chat.customer !== this.uid){
              this.msg.getContact(chat.customer).valueChanges()
              .subscribe((data) => {
                chat.contact = data;
            });
            } else {
              this.msg.getContact(chat.repairer).valueChanges()
              .subscribe((data) => {
                chat.contact = data;
            });
            }
            chat.lastUpdatedDate = this.msg.convertTime(chat.lastUpdated);
          };
      });
      this.msg.getAlertStats(this.uid).valueChanges()
      .subscribe((data) => {
          this.alertStats = [];
          this.alertStats = data;
      });
      } else {
        this.showLoading = false;
      }
  });

  this.referalLink= "mailto:?Subject=JustFixd&body=Check out JustFixd.com, where you can get items repaired by local repairers, or get paid to fix items: %0Dwww.justfixd.com"

}

getAds(){
  if (this.searchcatselect.length > 0 && this.searchpcInput.length > 3){
    jQuery('#collapseFilter').removeClass('in');
    this.ads = [];
    if (!this.searchdisselect){
      this.searchdisselect = 50;
    }
    if (this.searchissueselect.length > 0){ 
      this.router.navigateByUrl('?searchcat='+this.searchcatselect+'-'+this.searchsubcatselect+'&searchissue='+this.searchissueselect+'&searchpc='+this.searchpcInput+'&searchdis='+this.searchdisselect);
    } else if (this.searchsubcatselect.length > 0){
      this.router.navigateByUrl('?searchcat='+this.searchcatselect+'-'+this.searchsubcatselect+'&searchpc='+this.searchpcInput+'&searchdis='+this.searchdisselect);
    } else {
      this.router.navigateByUrl('?searchcat='+this.searchcatselect+'&searchpc='+this.searchpcInput+'&searchdis='+this.searchdisselect);
    }
  }
  else{
     this.toasr.error('Please select a search term and enter a postcode','Invalid search')
   }
  }

  showAd(ad){
  this.selectedad = ad;
  }

  getAlerts(){
    this.alerts = [];
    this.showAlertsSpinner = true;
    this.msg.getAlertsLatest(this.uid).valueChanges()
    .subscribe((data) => {
      this.alerts = data;
      this.unseen=false;
      console.log(this.alerts);
      for (let alert of this.alerts){
          alert.age = this.msg.getAge((+alert.time) * 1000);
          this.unseen=this.unseen || !alert.seen;
          alert.seen=true;
          this.msg.setAlertSeen(this.uid,alert);
      }
      this.showAlertsSpinner = false;
    });
  }
  deletAlert(event,alert){
    this.msg.deleteAlert(this.uid,alert);
    event.stopPropagation();
    return false;
  }
  readAlert(url, uid, time){
    this.msg.readAlert(url, uid, time);
  }
  showNotifDelete=false;
  swipeRight(){
    console.log("swipe");
    this.showNotifDelete=true;
  }
  resetSearch(){
    this.clearFilter();
    this.searchTerm = '';
  }

  clearFilter(){
    this.searchcatselect='';
    this.searchsubcatselect='';
    this.searchconselect='';
    this.searchageselect='';
    this.searchurgselect='';
    this.searchlisttypeselect='';
    }

    clearCats(){
    this.searchcatselect='';
    this.searchsubcatselect='';
    }

    updateCats(){

    }

    pickCat(catid){
      jQuery('.search-terms').fadeOut(100);
      this.searchcatselect = catid;
      this.searchsubcatselect = '';
    }

    pickSubCat(catid, subcatid) {
      this.pickCat(catid);
      this.searchsubcatselect = subcatid;
    }

serarchTerms(){
  // Declare variables
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById('myInput');
  filter = input.value.toUpperCase();
  ul = document.getElementById('myUL');
  li = ul.getElementsByTagName('li');

  // Loop through all list items, and hide those who don't match the search query
  for (i = 0; i < li.length; i++) {
    a = li[i].getElementsByTagName('a')[0];
    txtValue = a.textContent || a.innerText;
    if (txtValue.toUpperCase().indexOf(filter) > -1) {
      li[i].style.display = '';
    } else {
      li[i].style.display = 'none';
    }
  }
}

loadMoreAds(){
  this.listbit = this.listbit + 6;
}

introCats(){
  jQuery( '#intro-r-heading' ).css( 'font-size', '20px' ).css( 'color', '#666' ).css( 'margin-top', '0px' ).css( 'margin-bottom', '0px' );
  jQuery( '.intro-r-top' ).animate({ height: 0, opacity: 0 }, 'slow');
  jQuery( '.intro-r-top p' ).hide();
  this.pageVersion = 'r2';
}

introCat(catid){
  for (let category of this.categories){
    if (category.id === catid) {
      jQuery( '#intro-li-' + category.id ).css( 'margin-', '0px' );
      jQuery( '#intro-img-' + category.id ).css( 'width', '90px' );
      jQuery( '#intro-img-' + category.id ).css( 'height', '90px' );
      jQuery( '#intro-title-' + category.id ).delay( 505 ).css( 'font-size', '21px' );
      jQuery( '#intro-detail-' + category.id ).delay( 500 ).fadeIn(300);
    }
    else{
      jQuery( '#intro-li-' + category.id ).fadeOut(500);
    }
    jQuery( '.intro-back' ).show();
}
}

introCatBack(){
  jQuery( '.intro-title-cat' ).css( 'font-size', '17px' );
  jQuery( '.intro-li-cat' ).css( 'margin', '' );
  jQuery( '.intro-li-cat' ).show();
  jQuery( '.intro-detail-cat' ).hide();
  jQuery( '.intro-img-cat' ).css( 'width', '38px' );
  jQuery( '.intro-img-cat' ).css( 'height', '38px' );
  jQuery( '.intro-back' ).hide();
}

selectChat(chat) {
  this.modal.open('#messengerModal');
  this.modal.click('#chat-'+chat.id);
}


openProfile() {
  this.modal.open('#loginModal');
  this.modal.openTab('#profile');
}

openSettings() {
  this.modal.open('#loginModal');
  this.modal.openTab('#settings');
}

viewItems() {
  this.modal.open('#loginModal');
  this.modal.openTab('#activity');
}

openLoginModal() {
    this.modal.open('#loginModal');
}

}
