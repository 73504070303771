import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'topcategory',
    pure: false
})

export class CategoryPipe implements PipeTransform {

    transform(categories: any[], filter: Object): any {

      if (categories==null) return null;

        return categories.filter(function(category){
        return (category.top===true);
      })
    }

}
