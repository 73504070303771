import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) {return [];}
    if(!searchText) {return items;}
    if(searchText.length === 0) {return items};
    searchText = searchText.toLowerCase();
    return items.filter( it => {
      return (it.name.toLowerCase().includes(searchText));
    });
   }
}