import { Injectable } from '@angular/core';

declare var jQuery: any;

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  view: any;
  ad: any;

  constructor() {
    this.view = 'root';
  }

  open(id) {
    jQuery('.construction-alert').hide();
    jQuery('.modal').modal('hide');
    this.view = (id);
    this.showModal(id);
  }

  showModal(id) {
    setTimeout( () => {
      if (jQuery(id).length){
        jQuery(id).modal('show');
      } else {
        this.open(id);
      }
      }, 100 );
  }

  click(id) {
    setTimeout(() => jQuery(id).trigger('click'), 300);
  }

  close() {
    jQuery('.modal').modal('hide');
  }

  openTab(id){
    setTimeout( () => {
      if (jQuery('a[href="' + id + '"]').length){
        jQuery('a[href="' + id + '"]').tab('show');
      } else {
        this.openTab(id);
      }
      }, 100 );
  }
}
