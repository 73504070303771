import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { AuthService } from '../../services/auth.service';
import { Item } from '../../models/Item';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2'

declare var jQuery:any;

@Component({
  selector: 'bidding-form',
  templateUrl: './bidding-form.component.html',
  styleUrls: ['./bidding-form.component.css']
})
export class BiddingFormComponent implements OnInit, OnChanges {

  @Input()
  ad: any;
  @Input()
  bids: any;
  @Input()
  lowestBid: number;
  @Input()
  highestBid: number;
  @Input()
  customerItem: boolean;
  @Input()
  user: any;
  @Input()
  customer: any;
  scores: any;
  activeBid: number;
  commission: number;
  item: any;
  email: any;
  bidder: string;
  lastbidUid: any;
  lastBid: any;
  lastBidder: any;
  limit: number;
  readonly LOCAL_URL = 'assets/data/';
  formType: any;
  myLastBid: any;
  jobTime: any;
  bidDescription: any;
  swal(arg0: { title: string; text: string; icon: string; buttons: string[]; dangerMode: boolean; }) {
    throw new Error("Method not implemented.");
  }

  constructor(private auth: AuthService, private itemService: ItemService, public http: HttpClient) {}

  ngOnInit() {
    
    console.log(this.ad);
    this.commission = 0.05;
    this.formType = 'bid';
    this.itemService.getUser(this.ad.uid).valueChanges().subscribe((data) => {
      this.customer=data;
      this.customer.age = this.itemService.getAge(this.customer?.date * 1000);
    });
    this.http.get(this.LOCAL_URL + '/ratings.json')
    .subscribe(data => {
      this.scores = data[0].scores;
    });
    if (this.lowestBid){
      this.limit = this.lowestBid;
    } else if (this.ad.budget){
      this.limit = this.ad.budget;
    }
    this.resetForm();
    }

  onSubmit() {
    if (this.formType === 'bid'){
      const latestBid = this.bids[0];
      console.log('latest bid:' + latestBid);
       if (this.bids.length > 0 && (this.bids[0].uid === this.user.uid)){
        this.auth.toastr.error('You must wait for another repairer to make a bid before you can bid again.', 'Please wait to bid again', {timeOut: 8000} );
    } else if (this.limit && (this.activeBid > this.limit)){
      this.auth.toastr.error('You must bid lower than £'+ this.limit + ', or make an offer to explain why you would charge more.', 'Bid too high', {timeOut: 8000} );
    } else {
      this.bidDescription = null;
      this.submitBid();
    }
    } else if (this.formType === 'offer'){
      if (this.bidDescription.length < 100) {
        this.auth.toastr.error('Please make your explanation a bit longer.', 'Explanation too short', {timeOut: 8000} );
      } else {
        this.submitBid();
      }
    }
    }

  makeOffer(){
    this.formType = 'offer';
  }

  makeBid(){
    this.formType = 'bid';
  }

  submitBid(){
    const fee = (this.activeBid * this.commission);
    const feeRounded = +(Math.round(fee * 100) / 100);
    Swal.fire({
      title: 'Bid on item',
      html: 'If your bid is accepted, we will request a <b>£' + feeRounded.toFixed(2) + '</b> commision to put you in touch with the customer. If you do not confirm the winning bid withing 24 hours, the job may be given to another repairer.',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
    }).then((result) => {
      if (result.value) {
    const value = +(Math.round(this.activeBid * 100) / 100);
    const bid = {
      bid: value.toFixed(2),
      jobTime: this.jobTime,
      description: this.bidDescription,
      type: this.formType
    }
    this.itemService.bidItem(this.ad, this.user.uid, bid, this.bids, feeRounded);
    this.resetForm();
    }
   });
  }

  resetForm(){
    this.activeBid = undefined;
    this.jobTime = undefined;
    this.bidDescription = undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    jQuery(document).ready(function(){
      jQuery('[data-toggle="tooltip"]').tooltip({
        animated: 'fade',
        placement: 'bottom',
        html: true
    });
    });
}

}