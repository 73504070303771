import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-activity-summary',
  templateUrl: './user-activity-summary.component.html',
  styleUrls: ['./user-activity-summary.component.css']
})
export class UserActivitySummaryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
