import { Component, OnInit, Input } from '@angular/core';
import { floor } from 'lodash';
import { ItemService } from '../../services/item.service';
import { PaymentSingleService } from '../../services/payment_single.service';
import { environment } from '../../environments/environment';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ModalService } from '../../services/modal.service';

declare var jQuery:any;

interface Review {
  itemId?: string;
  reviewer?: string;
  ratings?: any;
}

@Component({
  selector: 'my-item',
  templateUrl: './my-item.component.html',
  styleUrls: ['./my-item.component.css']
})
export class MyItemComponent implements OnInit {

  @Input()
  item:any;
  @Input()
  user:any;
  @Input()
  ratings: any;
  handler: any;
  ratingItem: boolean;
  requirement: string;
  ratingForm: FormGroup;
  questions: any;
  scores: any;
  isRepairer: boolean;
  isCustomer: boolean;
  comments: string;
  userRatings: any;
  ratingAnswers: any;
  answers: any;
  answersTotal: number;
  answersArray: any[];
  tokenCreated: any;

  constructor(private itemService: ItemService, private paymentSvc: PaymentSingleService, public modal: ModalService) { }

  ngOnInit(){
    this.ratingItem = false;
    if (this.item.listingtype === 'f'){
      this.requirement = 'fix';
    } else if (this.item.listingtype === 'd'){
      this.requirement = 'diagnose';
    }
    if (this.ratings){
      this.questions = this.ratings[0].questions;
      this.scores = this.ratings[0].scores;
    }
    if (this.item.winningBidder === this.user.uid){
      this.isRepairer = true;
    } else {
      this.isCustomer = true;
    }
    }

  openChat() {
    this.modal.open('#messengerModal');
    this.modal.click('#chat-'+this.item.id+'-'+this.item.winningBidder);
  }

  confirmBid() {
    const fee = (this.item.winningBid * 2);
    this.handler = StripeCheckout.configure({
      key: environment.stripeKey,
      locale: 'auto',
      token: token => {
        this.paymentSvc.processPayment(token, fee);
        this.tokenCreated = token.created;
       this.itemService.confirmBid(this.item);
      }
    });
   this.handler.open({
     name: 'Confirm job',
     description: 'Get in contact with customer',
     image: this.item.img,
     currency: 'gbp',
     amount : fee,
     email: this.user.email
   });
 }
 
 rateItem(){
   this.ratingItem = true;
 }

 stopRateItem(){
  this.ratingItem = false;
}

selectScore(question, score){
for (let q of this.questions){
  if (q === question) {
    q.selectedScore = score.id;
    q.selectedScoreName = score.name;
    q.icon = score.icon;
  }
}
}

rateUser(){
  this.answersTotal = 0;
  this.answersArray = [];
  this.answers = [];
  for (let q of this.questions) {
      if (q.selectedScore) {
        const answer = {
          id : q.id,
          value : q.selectedScore
        };
        this.answers.push(answer)
      if (q.id !=='comments'){
        this.answersArray.push(q.selectedScore);
        this.answersTotal = this.answersTotal + (+q.selectedScore);
      }
      }
  }
    if (this.comments){
      const answer = {
        id : 'comments',
        value: this.comments
      };
      this.answers.push(answer)
    }
    this.answers.avg = ( (+this.answersTotal) / this.answersArray.length);
    const ratings = this.answers.map((obj)=> {return Object.assign({}, obj)});
    const review: Review = {
      itemId: this.item.id,
      reviewer: this.item.uid,
      ratings: ratings
    }
    if (this.isCustomer) {
      this.itemService.reviewRepairer(this.item, this.answers);
    } else {
      this.itemService.reviewCustomer(this.item, this.answers);
    }

}

}
