import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { Item } from '../../models/Item';

declare var jQuery:any;

@Component({
  selector: 'my-bid-table',
  templateUrl: './my-bid-table.component.html',
  styleUrls: ['./my-bid-table.component.css']
})
export class MyBidTableComponent implements OnChanges {
  @Input()
  myBids: any;
  @Input()
  user: any;

  constructor(public itemService: ItemService) { }

  ngOnChanges() {
    for (let bid of this.myBids){
      this.itemService.getMyItem(bid.item).valueChanges()
      .subscribe((data) => {
        bid.itemDetail = data;
      });
      const time = bid.time * 1000;
      bid.age = this.itemService.getAge(time);
      }
    jQuery('.myBid-modal').contents().appendTo('#myBids-modals-container');
  }

  openMyItem(id, status){
    if (status === 'listed') {
    jQuery('.modal').modal('hide');
    jQuery('#adModal-myBids-' + id).modal('show');
    }
   }
}
