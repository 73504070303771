import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../../services/modal.service';
import { HttpClient } from '@angular/common/http';
import { User } from '../../models/User';

type UserFields = 'email' | 'password'| 'confirmPassword';
type FormErrors = { [u in UserFields]: string };

import { Inject} from "@angular/core";
import { DOCUMENT } from '@angular/common';

import Swal from 'sweetalert2'
//import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';

declare var jQuery:any;

@Component({
  selector: 'login-modal',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {

@ViewChild('closeloginmodal') closeloginmodal: ElementRef;

  @Input() categories: any;
  showUpload: boolean;
  location: any;
  signupForm: FormGroup;
  signinForm: FormGroup;
  detailForm: FormGroup;
  profileForm: FormGroup;
  repairerForm: FormGroup;
  settingsForm: FormGroup;
  hideModal: boolean = false;
  page: any;
  passReset = false;
  emailVerified: boolean;
  formErrors: FormErrors = {
    'email': '',
    'password': '',
    'confirmPassword': '',
  };
  validationMessages = {
    'email': {
      'required': 'Email is required.',
      'email': 'Email must be a valid email',
    },
    'password': {
      'required': 'Password is required.',
      'pattern': 'Password must include at least one letter and one number.',
      'minlength': 'Password must be at least 4 characters long.',
      'maxlength': 'Password cannot be more than 40 characters long.',
    }
  };
  postcodeInvalid: boolean;
  selectedCats: any;
  lng: any;
  swal: any;

  constructor(@Inject(DOCUMENT) private document: Document, public fb: FormBuilder, public auth: AuthService, private http: HttpClient, public modal: ModalService) { 


    // First Step
    this.signupForm = this.fb.group({
      'email': ['', [
        Validators.required,
        Validators.email
        ]
      ],
      'password': ['', [
        Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        Validators.minLength(6),
        Validators.maxLength(25),
        Validators.required
      ]
    ],
      'confirmPassword': ['', [Validators.required] ]
    });

    // Second Step
    this.detailForm = this.fb.group({
      'givenName': ['', [
        Validators.maxLength(25),
        Validators.required
        ]
      ],
      'familyName': ['', [
        Validators.maxLength(25),
        Validators.required
        ]
      ],
      'emailsJobs': ['', [] ],
      'emailsNews': ['', [] ],
      'emailsListings': ['', [] ],
      'status': ['', [] ],
      'photoURL': ['', [] ]
    });

    // Update Profile
    this.profileForm = this.fb.group({
      'givenName': ['', [
        Validators.maxLength(25),
        Validators.required
        ]
      ],
      'familyName': ['', [
        Validators.maxLength(25),
        Validators.required
        ]
      ],
      'status': ['', [] ]
    });

    // Repairer Profile
    this.repairerForm = this.fb.group({
      'givenName': ['', [
        Validators.maxLength(25),
        Validators.required
        ]
      ],
      'familyName': ['', [
        Validators.maxLength(25),
        Validators.required
        ]
      ],
      'job': ['', [ Validators.required]],
      'description': ['', [ Validators.required]],
      'status': ['', [] ],
      'locationTypeS': ['', [] ],
      'locationTypeH': ['', [] ],
      'postcode': ['', [ Validators.required] ],
      'lng': ['', [] ],
      'lat': ['', [] ],
      'place': ['', [] ],
      'serviceCats': new FormArray([])
    });

    // Update Settings
    this.settingsForm = this.fb.group({
      'emailsJobs': ['', [] ],
      'emailsNews': ['', [] ],
      'emailsListings': ['', [] ]
    });

    // Sign in
    this.signinForm = this.fb.group({
      'email': ['', [
        Validators.required,
        Validators.email,
      ]],
      'password': ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(25),
      ]],
    });

  }

  ngOnInit() {
    this.buildServiceCats();
    this.signinForm.valueChanges.subscribe((data) => this.onValueChanged(data));
    this.onValueChanged(); // reset validation messages
    this.page = 'login';
    this.showUpload = false;
  }


  private buildServiceCats() {
    this.http.get('assets/data/categories.json')
    .subscribe(data => {
      this.categories = data;
      this.categories.map((o, i) => {
        const control = new FormControl(i === 0); // if first item set to true, else false
        (this.repairerForm.controls.serviceCats as FormArray).push(control);
      });
    });

  }

  // Updates validation state on form changes.
  onValueChanged(data?: any) {
    if (!this.signinForm) { return; }
    const form = this.signinForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field) && (field === 'email' || field === 'password' || field === 'confirmPassword')) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          if (control.errors) {
            for (const key in control.errors) {
              if (Object.prototype.hasOwnProperty.call(control.errors, key) ) {
                this.formErrors[field] += `${(messages as {[key: string]: string})[key]} `;
              }
            }
          }
        }
      }
    }
  }

  signIn() {
  this.auth.emailLogin(this.signinForm.value['email'], this.signinForm.value['password']);
  }

  resetPassword() {
    this.auth.resetPassword(this.signinForm.value['email'])
      .then(() => this.passReset = true);
  }

  // Using getters will make your code look pretty

  get email() { return this.signupForm.get('email') }
  get password() { return this.signupForm.get('password') }
  get confirmPassword() { return this.signupForm.get('confirmPassword') }

  get givenName() { return this.detailForm.get('givenName') }
  get familyName() { return this.detailForm.get('familyName') }
  get emailsJobs() { return this.detailForm.get('emailsJobs') }
  get emailsNews() { return this.detailForm.get('emailsNews') }
  get emailsListings() { return this.detailForm.get('emailsListings') }

  get givenNameC() { return this.profileForm.get('givenName') }
  get familyNameC() { return this.profileForm.get('familyName') }
  get statusC() { return this.profileForm.get('status') }

  get givenNameR() { return this.repairerForm.get('givenName') }
  get familyNameR() { return this.repairerForm.get('familyName') }
  get job() { return this.repairerForm.get('job') }
  get description() { return this.repairerForm.get('description') }
  get statusR() { return this.repairerForm.get('status') }
  get locationTypeS() { return this.repairerForm.get('locationTypeS') }
  get locationTypeH() { return this.repairerForm.get('locationTypeH') }
  get postcode() { return this.repairerForm.get('postcode') }
  get serviceCats() { return this.repairerForm.get('serviceCats') }

  get emailsJobsNew() { return this.settingsForm.get('emailsJobs') }
  get emailsNewsNew() { return this.settingsForm.get('emailsNews') }
  get emailsListingsNew() { return this.settingsForm.get('emailsListings') }

  get photoURL() { return this.profileForm.get('photoURL') }

  // Step 1
  signup() {
     return this.auth.emailSignUp(this.email.value, this.password.value)
   }

  // Step 2
  setDetails(user) {
    return this.auth.updateUser(user, { givenName: this.givenName.value, familyName: this.familyName.value, emailsJobs: this.emailsJobs.value || false, emailsListings: this.emailsListings.value || false, emailsNews: this.emailsNews.value || false })
  }

  //Profile update
  updateProfile(user) {
      return (this.auth.updateUser(user, { givenName: this.givenNameC.value, familyName: this.familyNameC.value, status: this.statusC.value }))
      .then(() => {
        this.auth.toastr.success('Your settings have been updated.', 'Profile');
        jQuery('#loginModal').animate({ scrollTop: 0 }, 'fast');
    });
    }
      // Settings update
      updateSettings(user) {
        return (this.auth.updateUser(user, { emailsJobs: this.emailsJobsNew.value || false, emailsNews: this.emailsNewsNew.value || false, emailsListings: this.emailsListingsNew.value || false}))
        .then(() => this.auth.toastr.success('Your settings have been updated.', 'Profile'));
      }

      //Repairer profile update
    updateProfileRepairer(form, user) {
      if (this.location && this.location.result) {
        user.lat =  this.location.result.latitude;
        user.lng = this.location.result.longitude;
        user.place = this.location.result.parish;
      }
      this.selectedCats = [];
      const formValue = Object.assign({}, form.value, {
        serviceCats: form.serviceCats.map((selected, i) => {
          if (selected === true) {
            this.selectedCats.push(this.categories[i].id);
      }})
      });
        if (!this.postcodeInvalid && user.place){
          return (this.auth.updateUser(user, { givenName: this.givenNameR.value, familyName: this.familyNameR.value, job: this.job.value, description: this.description.value, status: this.statusR.value, locationTypeS: this.locationTypeS.value, locationTypeH: this.locationTypeH.value, postcode: this.postcode.value, lng: user.lng, lat: user.lat, place: user.place, serviceCats: this.selectedCats }))
          .then(() => {
            this.auth.toastr.success('Your settings have been updated.', 'Profile');
            jQuery('#loginModal').animate({ scrollTop: 0 }, 'fast');
        });
          } else {
            this.auth.toastr.error('Please check and correct', 'Postcode not recognised');
          }
      }

      setToCustomer(user) {
          return (this.auth.updateUser(user, { status: this.statusR.value }))
          .then(() => {
            jQuery('#loginModal').animate({ scrollTop: 0 }, 'fast');
        });
          }

  registerPage(){
    this.page='register';
  }

  loginPage(){
    this.page='login';
  }

  getstarted(){
    this.page='login';
   }

  uploadDisplay(){
    if (this.showUpload === true){
      this.showUpload = false}
      else{
      this.showUpload = true
      }
      }

      becomeRepairer(user){
      Swal.fire({
        title: 'Become a repairer',
        text: "Upgrade your account to repairer status to allow you to bid on items to fix. We will require some additional details from you to allow this. ",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Let's go!"
      } as any).then((result) => {
        if (result.value) {
          this.profileForm.controls['status'].setValue('r');
          this.updateProfile(user);
        }
      })
    }

    becomeCustomerFirst(user){
      this.repairerForm.controls['status'].setValue('c');
      this.setToCustomer(user);
      jQuery('#loginModal').modal('hide');
      this.auth.toastr.success("You're ready to go. Simply activate your account using the email we have sent to "+user.email, 'Profile created'),{timeOut:8000};
    }

    becomeCustomer(user){
      Swal.fire({
        title: 'Become a customer only',
        text: "You will no longer be able to bid to fix items.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Set to customer'
      } as any).then((result) => {
        if (result.value) {
          this.repairerForm.controls['status'].setValue('c');
          this.setToCustomer(user);
          jQuery('#loginModal').animate({ scrollTop: 0 }, 'fast');
        }
      })
    }

    setStatus(user){
      if (!user.status){
      jQuery('#loginModal').modal('hide');
      const swalWithBootstrapButtons = Swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger',
        buttonsStyling: false,
      } as any)
      swalWithBootstrapButtons.fire({
        title: 'Welcome to JustFixd',
        text: "Set your account as a repairer by providing a few more details, or continue as a customer.",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Become a repairer',
        cancelButtonText: 'Continue as customer',
        reverseButtons: true
      } as any).then((result) => {
        if (result.value) {
          this.modal.open('#loginModal');
          this.becomeRepairer(user);
          jQuery('#loginModal').animate({ scrollTop: 0 }, 'fast');
        } else if (
          // Read more about handling dismissals
          result.dismiss === Swal.DismissReason.cancel
        ) {
          this.becomeCustomer(user);
          jQuery('#loginModal').animate({ scrollTop: 0 }, 'fast');
          swalWithBootstrapButtons.fire(
            "You're ready to go!",
            'List an item and wait for repairers to bid a fixing fee',
            'success'
          )
        }
      })
    }
    }

      updateLocation(){
        if (this.repairerForm.controls['postcode'].value.length > 0) {
          const shortpc = this.repairerForm.controls['postcode'].value.replace(/\s/g, "");
          const SEARCH_LOCATION_URL = 'https://api.postcodes.io/postcodes/'+shortpc;
          if (SEARCH_LOCATION_URL) {
          this.http.get(SEARCH_LOCATION_URL)
          .subscribe(
            data => {
              this.location = data;
              this.postcodeInvalid = false;
            },
            err => {
              this.location = undefined;
              this.postcodeInvalid = true;
              }
          )
        }
      }

      }

      goToSettings(){
        this.modal.openTab('#settings');
      }

      goToProfile(){
        this.modal.openTab('#profile');
      }

      sendVerificationEmail() {
        this.auth.sendEmailVerification();
      }

      googleLink() {
        this.auth.linkGoogle();
      }

      facebookLink() {
        this.auth.linkFacebook();
      }
      
      twitterLink() {
        this.auth.linkTwitter();
      }

}
