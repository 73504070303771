import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { LocationTidyPipe} from '../../pipes/locationtidy.pipe';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'cat-gallery',
  templateUrl: './cat-gallery.component.html',
  styleUrls: ['./cat-gallery.component.css']
})
export class CatGalleryComponent implements OnInit {

  @Input()
  categories: any;
  @Input()
  cat: any;
  @Input()
  user: any;
  items: any;

  constructor(private itemService: ItemService, private router: Router, public modal: ModalService) { }

  ngOnInit() {
    this.getItems();
  }

  getItems() {
    this.items = [];
    if (this.cat){
      this.itemService.getItems(this.user.lat, this.user.lng, this.cat.id, '', 50)
      .subscribe((data) => {
        this.items = data;
          }
  );
    }
}

  getAdsCat(catid, postcode){
    window.scroll(0,0);
     this.router.navigateByUrl('?searchcat='+catid+'&searchpc='+postcode+'&searchdis=50');
   }

}
