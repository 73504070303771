import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Init Firebase
import * as firebase from 'firebase/app';

// Init GeoFireX
import * as geofirex from 'geofirex';
const geo = geofirex.init(firebase);

import { auth } from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable ,  of ,  from } from 'rxjs';
import { switchMap, startWith, tap, filter } from 'rxjs/operators';
import { fromPromise } from 'rxjs/observable/fromPromise';

import { HttpRequest } from '@angular/common/http';
import decode from 'jwt-decode';

// var observableFromPromise =  from(promiseSrc);

import { ToastrService } from 'ngx-toastr';
import { floor } from 'lodash';

import { User } from '../models/User';
import { WindowService } from '../services/window.service';

//import { restoreView } from '@angular/core/src/render3';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import swal from 'sweetalert2';

declare var jQuery:any;
window["fb"]=firebase;
@Injectable({ providedIn: 'root' })
export class AuthService {

  user: Observable<User | null>;

  cachedRequests: Array<HttpRequest<any>> = [];

  windowRef: any;
  password: any;
  newEmail: any;
  userChecked: boolean;

  swal(arg0: { title: string; text: string; icon: string; buttons: string[]; dangerMode: boolean; }) {
    throw new Error("Method not implemented.");
  }

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  public retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    public toastr: ToastrService,
    public win: WindowService
  ) {
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        this.userChecked = true;
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
      //.tap(user => localStorage.setItem('user', JSON.stringify(user))),
      // startWith(JSON.parse(localStorage.getItem('user')))
    );
  }

//// Email/Password Auth ////

emailSignUp(email: string, password: string) {
  return this.afAuth.auth
    .createUserWithEmailAndPassword(email, password)
    .then(credential => {
      return this.updateUserData(credential.user); // if using firestore
    })
    .then(credential => {
      return this.afAuth.auth.currentUser.sendEmailVerification()
    })
    .catch(error => this.handleError(error));
}

sendEmailVerification() {
  return this.afAuth.auth.currentUser.sendEmailVerification()
  .then(() => this.toastr.info('Please check your inbox.', 'Verification email sent'))
}

emailLogin(email: string, password: string) {
  return this.afAuth.auth
    .signInWithEmailAndPassword(email, password)
    .then(credential => {
      return (
        this.completeLogin(credential.user)
      );
    })
    .catch(error => this.handleError(error));
}

verifyMail(oobCode: string, apiKey: string) {
    return this.afAuth.auth
    .applyActionCode(oobCode)
    .catch((error) => {
      console.log(error);
      throw error;
    });
  }

  setNewEmail() {
    Swal.fire({
      title: 'Set your new email address',
      html:
        '<br>Enter your password<br><input type="password" id="update-email-password" class="swal2-input"><br><br>' +
        'Set your new email address <input type="email" id="update-email-newEmail" class="swal2-input">',
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Update'
    }).then((result) => {
      if (result.value) {
        const password = (<HTMLInputElement>document.getElementById('update-email-password')).value;
        const newEmail = (<HTMLInputElement>document.getElementById('update-email-newEmail')).value;
         this.updateEmail(password, newEmail);
      }
    }
  )}

  updateEmail(password, newEmail){
    if (password && this.validateEmail(newEmail)) {
      firebase.auth()
      .signInWithEmailAndPassword(auth().currentUser.email, password)
      .then(credential => {
        credential.user.updateEmail(newEmail)
        .then(credential => {
          Swal.fire({
            title: 'Email updated',
            text: 'Please click the verification link which has been sent to ' + newEmail
          });
          const user = auth().currentUser;
          const data = {email: newEmail, emailVerified: false};
          this.afs.doc(`users/${user.uid}`).update(data);
          this.sendEmailVerification();
        })
        .catch(error => Swal.fire({
          title: 'Error with email change',
          text: error
        }));
    })
      .catch(error => Swal.fire({
        title: 'Error with email change',
        text: error
      }));
} else {
  Swal.fire({
    title: 'Form error',
    text: 'Please check that your password and new email address are valid'
  })}
  }

validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Sends email allowing user to reset password
resetPassword(email: string) {
  var auth = firebase.auth();
  return auth.sendPasswordResetEmail(email)
    .then(() => this.toastr.success('Reset link sent to ' + email, 'Email sent'))
    .catch((error) => console.log(error))
}

getAuth() { 
  return this.afAuth.auth; 
} 

verifyPasswordReset(oobCode: string) {
  firebase.auth().verifyPasswordResetCode(oobCode)
  .then(() => {
    return this.afAuth.auth
    .applyActionCode(oobCode)
  }).catch(e => {
    return e
  });
}

confirmPasswordReset(oobCode: string, newPassword: string) {
  firebase.auth().confirmPasswordReset(oobCode, newPassword).then(() => {
    return this.afAuth.auth;
  }).catch(e => {
    this.toastr.error(e, 'Error');
  });
}


 // Update properties on the user document
 updateUser(user: User, data: any) {
   if (user.displayName && !user.givenName && !user.familyName) {
   const str = user.displayName;
   const nameSplitted = str.split(" ", 2);
   user.givenName = nameSplitted[0];
   user.familyName = nameSplitted[1];
   } else {
   if(user.givenName && user.familyName) {user.displayName = user.givenName+' '+user.familyName; } else {user.displayName=''};
   }
   if (!user.locationTypeS) {
     user.locationTypeS = false;
   }
   if (!user.locationTypeH) {
    user.locationTypeH = false;
  }
  if (!user.serviceCats) {
    user.serviceCats = [];
  }

  if (!user.photoURL) {
    data.photoURL = 'https://www.justfixd.com/assets/img/user.png';
  }

  if (user.lat && user.lng) {
    const point = geo.point(user.lat, user.lng);
    data.position = point.data;
  }
  
   return this.afs.doc(`users/${user.uid}`).update(data)
 }

 // If error, console log and notify user
 private handleError(error) {
   console.error(error)
   this.toastr.error(error.message, 'Oh no! We have an Error...',{timeOut:8000});
 }

 googleLogin() {
  const provider = new auth.GoogleAuthProvider();
  return this.oAuthLogin(provider, 'google');
}

  facebookLogin() {
    const provider = new auth.FacebookAuthProvider()
    return this.oAuthLogin(provider, 'facebook')
  }

  linkGoogle() {
    const provider = new auth.FacebookAuthProvider();
    auth().currentUser.linkWithPopup(provider).then(result => {
      this.verifyAccount(result.user, 'google');
      this.toastr.success('Google account verified!');
    }).catch(error => this.toastr.error(error, 'Error'));
  }

  linkFacebook() {
    const provider = new auth.FacebookAuthProvider();
    auth().currentUser.linkWithPopup(provider).then(result => {
      this.verifyAccount(result.user, 'facebook');
      this.toastr.success('Facebook account verified!');
    }).catch(error => this.toastr.error(error, 'Error'));
  }

  linkTwitter() {
    const provider = new auth.FacebookAuthProvider();
    auth().currentUser.linkWithPopup(provider).then(result => {
      this.verifyAccount(result.user, 'twitter');
      this.toastr.success('Twitter account verified!');
    }).catch(error => this.toastr.error(error, 'Error'));
  }

  verifyPhone() {
    this.afs.collection('users').doc(auth().currentUser.uid).update( { phoneVerified : true});
  }

  completeLogin(user) {
    if (user.status) {
    jQuery('#loginModal').modal('hide');
    }
    this.toastr.success('Welcome!', 'Signed in');
    this.verifyUser(user);
  }

  verifyUser(user) {
    if (user.emailVerified) {
      this.afs.collection('users').doc(user.uid).update( { emailVerified : true});
    }
  }

  private oAuthLogin(provider: any, type) {
      return this.afAuth.auth
        .signInWithPopup(provider)
        .then(credential => {
          console.log(credential.user)
       return (this.updateUserData(credential.user)), (this.completeLogin(credential.user)), (this.verifyAccount(credential.user, type));
        })
        .catch(error => this.handleError(error));   
    }

    verifyAccount(user, type) {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      if (type === 'google') {
        userRef.update( { googleVerified : true});
      } else if (type === 'facebook') {
        userRef.update( { facebookVerified : true});
      } else if (type === 'twitter') {
        userRef.update( { twitterVerified : true});
      }
    }

  private updateUserData(user) {
    // Sets user data to firestore on login

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    if (user.displayName && !user.givenName && !user.familyName) {
    const str = user.displayName;
    const nameSplitted = str.split(' ', 2);
    user.givenName = nameSplitted[0];
    user.familyName = nameSplitted[1];
    }

    const data: User = {
      uid: user.uid,
      email: user.email,
      photoURL : user.photoURL || 'https://www.justfixd.com/assets/img/user.png',
      date: user.date || (+ new Date / 1000),
      position: user.position || null
    }

    return userRef.set(data, { merge: true })

  }

  getServiceCats(uid) {
    return uid;
//    const data = this.afs.collection('users').document(uid).valueChanges();
//    if (data.serviceCats){
//      return data.serviceCats
//    }
//    else{
//      return 'test';
//    }
    }

      // Used by the http interceptor to set the auth header
  getUserIdToken(): Observable<string> {
    return fromPromise ( this.afAuth.auth.currentUser.getIdToken() );
  }

  upgradeUser(uid) {
    this.afs.collection('users').doc(uid).update( { upgrade : true, upgradeDate: (+new Date() / 1000)});
  }

  async signOut() {
    await this.afAuth.auth.signOut();
    this.router.navigate(['/']);
  }
}
