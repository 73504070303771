import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'locationtidy'})
export class LocationTidyPipe implements PipeTransform {
  transform(value: string): string {
    if (value === null) {return null}
    else{
    var centre = value.replace(/, unparished area/g, '');
    return centre.replace(/ and /g, ' / ');
    }
  }
}
