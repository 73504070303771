
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { Item } from '../models/Item';
import { User } from '../models/User';
// Init Firebase
import * as firebase from 'firebase/app';
// Init GeoFireX
import * as geofirex from 'geofirex';
const geo = geofirex.init(firebase);

import { AuthService } from './auth.service';
import { AngularFireAuth } from '@angular/fire/auth';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

import { floor } from 'lodash';
//import { restoreView } from '@angular/core/src/render3';

import { PaginationService } from '../services/pagination.service';

declare var jQuery:any;

@Injectable()
export class ItemService {

  recDoc: AngularFirestoreDocument<{}>;
  bidder: any;
  myItem: AngularFirestoreDocument<{}>;
  itemsCollectionSection: any;
  bidDoc: AngularFirestoreDocument<{}>;
  ratingDoc: AngularFirestoreDocument<{}>;
  ratingTotal: any;
  ratingCount: any;
  newRatingTotal: number;
  newRatingCount: number;
  ratingsAvg: any;
  oldRatingCount: any;
  oldRatingAvg: any;
  commission: number;
  recipients: any;
  recipientNote: string;
  swal(arg0: { title: string; text: string; icon: string; buttons: string[]; dangerMode: boolean; showCancelButton: boolean; confirmButtonColor: string; confirmButtonText: string; cancelButtonColor:string }) {
    throw new Error("Method not implemented.");
  }
  itemsCollection: AngularFirestoreCollection<Item>;
  items: Observable<Item[]>;
  itemDoc: AngularFirestoreDocument<Item>;
  userId: any;
  userDoc: AngularFirestoreDocument<{}>;

  constructor(public afs: AngularFirestore, public afAuth: AngularFireAuth, public auth: AuthService, public page: PaginationService) {
    this.afAuth.authState.subscribe(user => {
       if (user) {this.userId = user.uid; }
     });

     this.commission = 0.05;
  }


  getItems(lat, lng, cat, issue, dis){
    const center = geo.point(lat, lng);
    // convert miles to km
    const radius = (dis * 1.609);
    const field = 'position';
    if (issue.length > 0 && cat.length > 1){
      const itemsCollection = geo.collection('items', ref => ref.where('issue', '==', issue).where('subcategory', '==', cat).where('category', '==', cat).where('status', '==',  'listed').limit(6));
      const items = itemsCollection.within(center, radius, field)
      return items;
      }
    else if (issue.length > 0){
        const itemsCollection = geo.collection('items', ref => ref.where('issue', '==', issue).where('status', '==',  'listed').limit(6));
        const items = itemsCollection.within(center, radius, field)
        return items;
      }
    else if (cat.length > 1){
    const itemsCollection = geo.collection('items', ref => ref.where('subcategory', '==', cat).where('status', '==',  'listed').limit(6));
    const items = itemsCollection.within(center, radius, field)
    return items;
    } else {
    const itemsCollection = geo.collection('items', ref => ref.where('category', '==', cat).where('status', '==',  'listed').limit(6));
    const items = itemsCollection.within(center, radius, field)
    return items;
    }
  }


  getSingleItem(id){
    return this.afs.doc<Item>(`items/${id}`);
  }

  addItem(item: Item) {
    this.itemsCollection = this.afs.collection('items');
    const point = geo.point(item.lat, item.lng);
    item.position = point.data;
    item.date = firebase.firestore.FieldValue.serverTimestamp();
    this.itemsCollection.doc(item.id).set(item);
    // convert miles to km
    const radius = (50 * 1.609);
    const field = 'position';
    const recipientCollection = geo.collection('users', ref => ref
    .where('status', '==',  'r')
    .where('serviceCats', 'array-contains', item.category));
    const nearbyRecipients = recipientCollection.within(point, radius, field)
    .subscribe(data => {
        this.recipients = data;
        console.log(this.recipients);
        this.recipientNote = '';
        if (this.recipients.length > 1){
          this.recipientNote = " We've let " + this.recipients.length + " repairers nearby know that this item has been listed."
        }
        for (let user of this.recipients){
          if (user.uid !== item.uid){
            this.sendAlert(user.uid, item.title, 'An item has been listed nearby', item.img, 'listedItem|' + item.id, 'new-' + item.listingtype )
          }
                    }
        jQuery('#submitListingModal').modal('hide');
        Swal.fire({
          title: 'Item listed',
          text: 'Your item has been listed. We\'ll notify you when you start receiving bids.' + this.recipientNote,
          icon: 'success',
          confirmButtonText: 'Continue'
        } as any);
    });
  }

  deleteItem(item: Item) {
    Swal.fire({
      title: 'Delete item',
      text: 'Are you sure you want to delete this item? This cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        jQuery('.modal').modal('hide');
        this.itemDoc = this.afs.doc(`items/${item.id}`);
        this.itemDoc.delete();
        this.userDoc = this.afs.doc(`users/${item.uid}`);
        this.recDoc = this.userDoc.collection('items').doc(item.id);
        this.userDoc.collection('bids').doc(item.id).delete();
        this.recDoc.delete();
        Swal.fire(
          'Item deleted.',
          'This item has been removed from the site.',
          'success'
        );
      }
    });
  }

  updateItem(item: Item) {
    this.itemDoc = this.afs.doc(`items/${item.id}`);
    this.itemDoc.update(item);
    Swal.fire({
      title: 'Item updated',
      text: "Your item has been updated.",
      type: 'success',
      confirmButtonText: 'Continue'
    } as any);
  }

  bidItem(item: Item, uid, bid, otherbids, fee) {
        this.bidDoc = this.afs.doc(`bids/${item.id}-${uid}`);
        const newDate = new Date();
        const time =  floor(+newDate / 1000);
        this.bidDoc.set({
          item: item.id,
          uid: uid,
          bid: bid.bid,
          jobTime: bid.jobTime,
          description: bid.description,
          type: bid.type,
          time: time,
          currency: 'GBP',
          fee: fee.toFixed(2)
        });
    for (let oldBid of otherbids){
    const newBid = bid.bid;
      if (oldBid.bid > newBid && oldBid.type === 'bid' && bid.uid !== oldBid.uid){
      this.sendAlert(oldBid.uid, item.title, 'You have been outbid', item.img, 'listedItem|' + item.id, 'outbid' )
    }
    }
    this.sendAlert(item.uid, item.title, '£' + bid.bid +' offer received', item.img, 'listedItem|' + item.id, 'offer' );
    Swal.fire({
      title: 'Bid submitted',
      text: "Your bid has been submitted. We'll let you know if it's accepted.",
      type: 'success',
      confirmButtonText: 'Continue'
    } as any)
    }

  getBids(itemId){
    const bids = this.afs.collection('bids', ref => ref.where('item', '==', itemId).orderBy('time','desc'));
    return bids;
  }

  getUser(uid){
    this.userDoc = this.afs.doc(`users/${uid}`);
    return this.userDoc;
  }

  getMyItems(uid, status){
    this.itemsCollection = this.afs.collection('items', ref => ref.where('uid', '==', uid).where('status', '==', status).orderBy('date','desc'));
    return this.itemsCollection;
  }

  getMyJobs(uid, status){
    this.itemsCollection = this.afs.collection('items', ref => ref.where('winningBidder', '==', uid).where('status', '==', status));
    return this.itemsCollection;
  }

  getMyBids(uid){
  this.page.init('bids', 'time', { reverse: true, prepend: false, opts: {findValue: 'uid', conditionType: '==', matchValue: uid, limit: 7} });
}

  getMyItem(id){
    this.itemDoc = this.afs.doc(`items/${id}`);
    return this.itemDoc;
  }

  getMyListedItem(id){
    const item = this.afs.collection(`items`, ref => ref.where('id', '==', id).where('status', '==', 'listed'));
    return item;
  }

  acceptBid(item, bid) {
    const user = bid.user;
        Swal.fire({
          html:
          "<div class='bidder-img' style='background-image:url(" + user.photoURL + ")'></div><h2>" + user.givenName + " offers £" + bid.bid + "</h2><br>We'll ask " + user.givenName + " to confirm the job and contact you.",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText:
            '<i class="fa fa-thumbs-up"></i> Accept bid!',
          confirmButtonAriaLabel: 'Accept!',
          cancelButtonText:
            '<i class="fa fa-thumbs-down"></i> Not yet',
          cancelButtonAriaLabel: 'Not yet',
        }).then((result) => {
          if (result.value) {
            this.itemDoc = this.afs.doc(`items/${item.id}`);
            const itemAcceptedInfo: Item = {
              status: 'accepted',
              winningBid: bid.bid,
              winningBidder: bid.uid,
              winningBidderName: user.givenName,
           //   winningBidFee: bid.fee.toFixed(2)
            winningBidFee: bid.fee
            };
            this.itemDoc.update(itemAcceptedInfo);
            Swal.fire(
              'Accepted!',
              'Your repairer should be in touch shortlly once they have confirmed the job. If you do not hear from them within 24 hours you may accept a different bid.',
              'success'
            );
            jQuery('.modal').modal('hide');
            this.sendAlert(bid.uid, item.title, 'Offer accepted', item.img, 'myjobs', 'accepted' )
          }
        });
      }

      revokeAccept(item) {
            Swal.fire({
              title: 'Revoke?',
              text: 'You accepted this bid. Do you want to revoke this now?',
              type: 'warning',
              showCloseButton: true,
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonText:
                '<i class="fa fa-ban"></i> Revoke',
              confirmButtonAriaLabel: 'Revoke',
              cancelButtonText:
                'Cancel',
              cancelButtonAriaLabel: 'Cancel',
            } as any ).then((result) => {
              if (result.value) {
                this.itemDoc = this.afs.doc(`items/${item.id}`);
                const itemAcceptedInfo: Item = {
                  status: 'listed',
                  winningBid: null,
                  winningBidder: null,
                  winningBidderName: null,
                  winningBidFee: null
                };
                this.itemDoc.update(itemAcceptedInfo);
                Swal.fire(
                  'Revoked',
                  'You may accept another bid or accept a bid from this bidder again.',
                  'success'
                );
              }
            });
          }

          confirmBid(item) {
            this.itemDoc = this.afs.doc(`items/${item.id}`);
            const itemConfirmedInfo: Item = {
              status: 'confirmed',
            };
            this.itemDoc.update(itemConfirmedInfo);
            const newDate = new Date();
            const time =  +newDate / 1000;
            const customerChat =
            this.afs.doc(`users/${item.uid}/chats/${item.id}-${item.winningBidder}`);
            const repairerChat =
            this.afs.doc(`users/${item.winningBidder}/chats/${item.id}-${item.winningBidder}`);
            const firstMessage = {
              'uid' : item.winningBidder,
              'body' : 'Job confirmed: ' + item.title,
              'time' : time,
              };
            const chat = {
              id: item.id + '-' + item.winningBidder,
              customer: item.uid,
              repairer: item.winningBidder,
              lastMessage : firstMessage,
              lastUpdated : time,
              unread : true,
              item: item
              };
              customerChat.set(chat);
              repairerChat.set(chat);
              const timeFile = time.toString();
              const message = this.afs.collection('chats').doc(item.id+'-'+item.winningBidder)
              .collection('messages').doc(timeFile);
              message.set(firstMessage);
              this.sendAlert(item.uid, item.title, 'Job confirmed', item.img, 'myitems', 'confirmed' )
            }

        getAge(itemDate){
          const time = (+ new Date / 1000);
          const rectime = (+itemDate / 1000);
          const age = (time - rectime);
          const secondsOld = floor(age);
          const daysOld = floor(secondsOld / (3600 * 24));
          const hoursOld = floor(secondsOld / 3600);
          const minutesOld = floor(secondsOld / 60);
          const weeksOld = floor(daysOld / 7);
          const monthsOld = floor(weeksOld / 4.34524);
          const yearsOld = floor(daysOld / 365.2422);
          if (yearsOld > 1) {
            return (yearsOld+' years ago').toString();
          }
          else if (yearsOld === 1) {
            return ('a year ago').toString();
          }
          else if (monthsOld > 1) {
            return (monthsOld+' months ago').toString();
          }
          else if (monthsOld === 1) {
            return ('a month ago').toString();
          }
          else if (weeksOld > 1) {
            return (weeksOld+' weeks ago').toString();
          }
          else if (weeksOld === 1) {
            return ('a week ago').toString();
          }
          else if (daysOld > 1) {
            return (daysOld+' days ago').toString();
          }
          else if (daysOld === 1) {
            return (daysOld+' day ago').toString();
          }
          else if (hoursOld > 1){
            return (hoursOld+' hours ago').toString();
          }
          else if (hoursOld === 1){
            return ('an hour ago').toString();
          }
          else if (minutesOld > 1){
            return (minutesOld+' minutes ago').toString();
          }
          else if (minutesOld === 1){
            return ('a minute ago').toString();
          }
          else {
            return 'just now'
          }
        }
        reviewRepairer(item, ratings){
          this.itemDoc = this.afs.doc(`items/${item.id}`);
          const itemCompletedInfo: Item = {
            status: 'completed',
          };
          this.itemDoc.update(itemCompletedInfo);
          this.storeRating(item, ratings, item.winningBidder, item.uid, 'r');
          Swal.fire({
            title: 'Review submitted',
            text: 'Many thanks for reviewing your repaier.',
            type: 'success',
            confirmButtonText: 'Continue'
          } as any);
        }

        reviewCustomer(item, ratings){
          this.itemDoc = this.afs.doc(`items/${item.id}`);
          const itemCompletedInfo: Item = {
            status: 'closed',
          };
          this.itemDoc.update(itemCompletedInfo);
          this.storeRating(item, ratings, item.uid, item.winningBidder, 'c');
          Swal.fire({
            title: 'Review submitted',
            text: 'Many thanks for reviewing this customer.',
            type: 'success',
            confirmButtonText: 'Continue'
          } as any);
        }

        storeRating(item, ratings, target, source, type){

          const UserRef = this.afs.doc<User>(`users/${target}`);

          // Create a reference for a new rating, for use inside the transaction
          const ratingRef = UserRef.collection('ratings').doc(item.id);

         // In a transaction, add the new rating and update the aggregate totals
          return firebase.firestore().runTransaction(transaction => {
          return transaction.get(UserRef.ref).then(res => {
          if (!res.exists) {
              throw 'Document does not exist!';
          }

          if (res.data().ratingCount){
            this.oldRatingCount = res.data().ratingCount;
          } else {
            this.oldRatingCount = 0;
          }

          if (res.data().ratingAvg){
            this.oldRatingAvg = res.data().ratingAvg;
          } else {
            this.oldRatingAvg = 0;
          }

          // Compute new number of ratings
          const newNumRatings = this.oldRatingCount + 1;

          // Compute new average rating
          const oldRatingTotal = this.oldRatingAvg * this.oldRatingCount;
          const newAvgRating = (oldRatingTotal + ratings.avg) / newNumRatings;

          // Commit to Firestore
          if (newAvgRating){
          transaction.update(UserRef.ref, {
              ratingCount: newNumRatings,
              ratingAvg: newAvgRating
          });
          }
          const newDate = new Date();
          const time =  +newDate / 1000;
          const ratingContent = {
            itemId: item.id,
            itemTitle: item.title,
            itemCat: item.category,
            itemSubcat: item.subcategory,
            itemImg: item.img,
            reviewer : source,
            time : time,
            type : type,
            ratings : ratings,
            avg: ratings.avg
            };
          transaction.set(ratingRef.ref, ratingContent);
      })
  });
}

        getMyRatings(uid){
          const ratings = this.afs.collection(`users/${uid}/ratings/`, ref => ref.orderBy('time', 'desc').limit(6));
          return ratings;
        }

        sendAlert(uid, subject, text, img, url, type ) {
          const time = new Date();
          const unix =  +time / 1000;
          const alertDoc = this.afs.firestore.doc(`/users/${uid}/alerts/${unix}`);
          const statsDoc = this.afs.firestore.doc(`/users/${uid}/alerts/--stats--`);
          const alert = {
            subject: subject,
            text: text,
            img: img,
            url: url,
            type: type,
            read: false,
            time: unix
          };
          const increment = firebase.firestore.FieldValue.increment(1);
          const batch = this.afs.firestore.batch();
          batch.set(alertDoc, alert);
          batch.set(statsDoc, { alertCount: increment }, { merge: true });
          batch.commit();
          };

}
