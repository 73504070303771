import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'user-summary',
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.css']
})
export class UserSummaryComponent implements OnInit {

  @Input()
  user: any;
  @Input()
  anon: boolean;
  initial: string;

  constructor() { }

  ngOnInit() {
  this.initial = this.user.familyName.charAt(0);
  }

}
