import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MessagingService } from '../../services/messaging.service';
import { ModalService } from '../../services/modal.service';

declare var jQuery:any;

@Component({
  selector: 'alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

  @Input()
  user:any;

  constructor(public msg: MessagingService, public router: Router, public modal: ModalService) { }

  ngOnInit() {
    this.msg.getAlerts(this.user.uid);
  }

  loadMore() {
    this.msg.page.more();
  }

  readAlert(url, uid, time){
    this.msg.readAlert(url, uid, time);
  }

  goToUrl(url){
    this.router.navigateByUrl(url);
  }

}
