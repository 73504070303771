import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'action',
  templateUrl: './action.component.html',
  styles: []
})
export class ActionComponent implements OnInit {
  wasVerified = false;
  wasVerifiedError = false;
  wasPasswordReset = false;
  wasPasswordResetError = false;
  oobCode: string;
  passwordResetError: boolean;

  constructor(private activatedRoute: ActivatedRoute,
    private auth: AuthService, private router: Router) { }

  ngOnInit() {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const mode = queryParams['mode'];
    const apiKey = queryParams['apiKey'];
    this.oobCode = queryParams['oobCode'];
    if (mode === 'verifyEmail') {
      this.auth.verifyMail(this.oobCode, apiKey)
      .then(() => this.wasVerified = true)
      .catch(() => this.wasVerifiedError = true);
      this.auth.signOut();
    } else if (mode === 'resetPassword') {
      this.auth.getAuth().verifyPasswordResetCode(this.oobCode)
      .then(() => this.wasPasswordReset = true)
      .catch(() => this.wasPasswordResetError = true);
    }
  }

  onSetPassword(form: NgForm) {
    this.auth.getAuth().confirmPasswordReset(
     this.oobCode,
     form.value.password
   ).then(resp => {
     this.auth.toastr.success('Your new password has been successfull set', 'Password set');
     this.router.navigateByUrl('/?signin=active')})
     .catch(e => {
     this.auth.toastr.error(e, 'Error');
     });
   }

}
