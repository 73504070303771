import { Component, OnInit, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { ItemService } from '../../services/item.service';
import { LocationTidyPipe } from '../../pipes/locationtidy.pipe';
import { ItemIdPipe } from '../../pipes/itemid.pipe';
import { ModalService } from '../../services/modal.service';

declare var jQuery:any;

@Component({
  selector: 'adpage',
  templateUrl: './adpage.component.html',
  styleUrls: ['./adpage.component.css']
})
export class AdpageComponent implements OnInit {

  @Input()
  count: any;
  @Input()
  ad: any;
  @Input()
  categories: any;
  @Input()
  conditions: any;
  @Input()
  location: any;
  @Input()
  userdistancemetric: any;
  @Input()
  preview: boolean;
  @Input()
  img: string;
  @Input()
  user: any;

  distance: any;
  place: any;
  bidding: boolean;
  skippedPayment: boolean;
  bids: any;
  bidsValues: any;
  lowestBid: number;
  highestBid: number;
  customer: any;
  carouselTarget: string;
  carouselName: string;
  imgView: any;
  notRepairer: boolean;
  uid: string;
  descriptionFull: boolean;

  constructor(private http: HttpClient, private auth: AuthService, private itemService: ItemService, public modal: ModalService) { 
    }

    ngOnInit(){
        if (this.user.givenName === undefined || this.user.familyName === undefined || this.user.place === undefined || this.user.status !== 'r' || !this.user.emailVerified){
          this.notRepairer = true;
        } else {
          this.notRepairer = false;
        }
        console.log(this.ad);
        if (this.ad && this.ad.uid){
          this.itemService.getUser(this.ad.uid).valueChanges().subscribe((data) => {
            this.customer = data;
            this.customer.age = this.itemService.getAge(this.ad.date.seconds * 1000);
          });
          }
          this.carouselName = this.count + 'Carousel';
          this.carouselTarget = '#' + this.carouselName;
          this.imgView = 'item';
          if (this.ad){
          this.ad.age = this.itemService.getAge(this.ad.date.seconds * 1000);
          }
          this.loadBids();
    }

  loadBids(){
    if (this.ad){
      this.itemService.getBids(this.ad.id).valueChanges().subscribe((data) => {
        this.bids = data;
        this.lowestBid = null;
        this.bidsValues = [];
        for (const bid of this.bids){
            this.bidsValues.push(bid.bid * 1);
            this.highestBid = this.bidsValues.reduce((a, b)=>Math.max(a, b)); 
            this.lowestBid = this.bidsValues.reduce((a, b)=>Math.min(a, b)); 
        }
      });
    }
    }

    bid() {
   //   if (this.user.uid !== this.ad.uid) {
      this.bidding = true;
   // } else {
   //   this.auth.toastr.error('You cannot bid on your own item!', 'Not available', {timeOut: 8000} );
   // }
    }

    stopBidding() {
      this.bidding = false;
    }

    viewIssue() {
     this.imgView = 'issue';
    }

    viewItem() {
      this.imgView = 'item';
    }

    deleteItem() {
      this.itemService.deleteItem(this.ad);
    }

    goToLogin(){
      this.modal.open('#loginModal');
      this.modal.openTab('#profile');
    }

    descriptionExpand(){
      this.descriptionFull = true;
    }

  }
