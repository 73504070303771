import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument,  } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';


@Injectable()
export class PaymentSingleService {

  userId: string;

  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth) {
    this.afAuth.authState.subscribe((auth) => {
      if (auth) this.userId = auth.uid
    });
  }


   processPayment(token: any, amount: number) {
     const payment = { token, amount }
     this.afs.collection('payments').doc(this.userId + '-' + token.created).set(payment);
     this.afs.collection('user').doc(this.userId).update({paymentVerified : true});
    }

}